import React from 'react';
import { Helmet } from 'react-helmet-async';

import { AUTH_ENDPOINT } from '../components/api-backend'
import EmailValidator from 'email-validator'
import Roller from '../roller/Roller';

async function storeEmail(body) {
  console.log("MailCompose sendMail")
  const sendMailResp = fetch(AUTH_ENDPOINT, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  })
    .then(data => data.json())
    .catch(error => (
      console.log(error)
    ))
  console.log(sendMailResp)
  return sendMailResp
}

export default class SignUpEmail extends React.Component {
  // const [tmpEmail, setTmpEmail] = useState(null);
  // const [setEmail] = props;
  // const [divCheck, setDivCheck] = useState()
  constructor(props) {
    super(props);
    this.state = {
      email: null,
      loading: false,
      reset: props.reset === undefined ? false : true
    }
    this.setEmail = props.setEmail
    console.log("reset " + this.state.reset)
  }

  validEmail(email) {
    let valid = EmailValidator.validate(email)
    console.log("SignUpEmail.validEmail " + valid)
    return valid
  }


  getCheckEmailDiv(tmpEmail) {
    let divCheck = <div className="check-failed">X</div>
    if (this.validEmail(tmpEmail)) {
      divCheck = <div className="check-passed">&#10003;</div>
    }
    return divCheck
  }

  async handleSendClick(emailValue) {
    const { reset } = this.state
    console.log("SignUpEmail.handleSendClick " + emailValue)
    this.setState({ 'loading': true })
    let method = "create"
    if (reset) {
      method = "password_reset"
    }
    const payload = {
      "method": method,
      "email": emailValue
    }
    console.log("SignUpEmail.handleSendClick storing - " + JSON.stringify(payload))
    const storeResp = await storeEmail(payload);
    console.log("SignUpEmail.handleSendClick storeResp " + storeResp)
    this.setEmail(emailValue)
    this.setState({ 'loading': false })
  }


  // const { email } = this.state;
  render() {
    const { email, loading, reset } = this.state;

    let btn = document.getElementById("sign-up-btn")
    let buttonDisabled = true
    if (btn !== null) {
      if (this.validEmail(email)) {
        buttonDisabled = false
      }
    }

    let process = "registration"
    if (reset) {
      process = "password reset"
    }

    return (<div>
      <Helmet>
        <title>Sign Up | Elliott Technologies</title>
        <meta name="keywords" content="arvada,denver,colorado,register,sign up" />
      </Helmet>
      <h2>Enter Email</h2>
      <div align='center'>
        <div className="signup-message">
          We'll send a verification code to the email address
          you provide in order to proceed with the {process} process.
        </div>
      </div>
      <table align='center'>
        <tbody>
          <tr>
            <td align="right">
              Email
            </td>
            <td>
              <input type="email" onChange={e => this.setState({ email: e.target.value })} />
            </td>
          </tr>
        </tbody>
      </table>
      <div style={{ padding: "5px" }}>
        <button id="sign-up-btn"
          className="login-btn"
          disabled={buttonDisabled}
          onClick={() => this.handleSendClick(email)}>Send</button>
      </div>
      <br />
      <table className="chec-table" align='center'><tbody>
        <tr>
          <td className='check-table-td'><div >{this.getCheckEmailDiv(email)}</div></td>
          <td style={{ textAlign: "left" }}>
            Valid email address format.
          </td>
        </tr>
      </tbody></table>
      {loading ? <Roller /> : null}
    </div>
    )
  }
}