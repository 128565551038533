import React from 'react';
import MailMsgRow from './MailMsgRow'
import { EMAIL_ENDPOINT } from './api-backend';
import './MailFolderItems.css';
import MailMessage from './MailMessage'
import Roller from '../roller/Roller'
import ResetTimeout from '../app/resetTimeout'

export default class MailFolderItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailInfo: props.emailInfo,
      folder: props.folder,
      folders: props.folders,
      mailbox: props.mailbox,
      mail_items: null,
      selected_items:[],
      loading: false
    };
    console.log("MailFolderItems.constructor: " + props.folder)

    this.handleDeleteCLick = this.handleDeleteCLick.bind(this)
    this.hanldeCheckboxChange = this.hanldeCheckboxChange.bind(this)

    this.messageRef = React.createRef()

    this.composeTo = props.composeTo
    this.composeCc = props.composeCc
    this.composeSubject = props.composeSubject
    this.composeSetSelectedFile = props.composeSetSelectedFile
  }

  componentDidMount() {
    console.log("MailFolderItems.componentDidMount")
    console.log("MailFolderItems.componentDidMount: folder " + this.state.folder)
    this.fetchFolderItems()
  }
  componentDidUpdate() {
    console.log("MailFolderItems.componentDidUpdate")
    if (this.state.mail_items === null) {
      return
    }
  }

  getNoMessages(folder){
    console.log("MailFolders.getNoMessages: " + folder)
    return (
      <tbody key={folder}>
        <tr>
          <td>
            <div className="no-messages">No messages to display</div>
          </td>
        </tr>
      </tbody>
    )
  }


  async moveMessage(folder) {
    console.log("MailFolderItems.moveMessage: "  + this.state.selected_items)
    this.setState({loading:true})
    const token = sessionStorage.getItem('token');
    const request = {
      "action": "move",
      "token" : token,
      "messageId": this.state.selected_items.toString(),
      "folder": folder,
      "mailbox": this.state.mailbox
    }
    
    await fetch(EMAIL_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
      .then(data => {
        const json = data.json()
        console.log("MailFolderItems.moveMessage: json: " + json);
        this.setState({loading:false})
        return json
      })
      .then(json => {
        console.log("MailFolderItems.moveMessage: status", json.success)
        if (!json.success) {
          throw Error("call to move message failed")
        }
        console.log("MailFolderItems.moveMessage: sucessfully moved")
        this.setState({selected_items:[]})
        this.fetchFolderItems()
        this.setState({loading:false})
        return json.success
      })
      .catch( err => {
        console.log("MailFolderItems.moveMessage: Error: " + err)
      });
    this.setState({loading:false})
  }

  hanldeCheckboxChange = async e =>{
    console.log("MailFolderItems.hanldeCheckboxChange: value " + e.target.value)
    console.log("MailFolderItems.hanldeCheckboxChange: selected " + e.target.checked)
    var selected = this.state.selected_items
    if (e.target.checked) {
      selected.push(e.target.value)
    } else {
      var index = selected.indexOf(e.target.value)
      console.log("MailFolderItems.hanldeCheckboxChange: value index " + index)
      if (index !== -1) {
        console.log("MailFolderItems.hanldeCheckboxChange: splicing " + index)
        selected.splice(index, 1);
      }
    }
    this.setState({selected_items:selected})
    console.log("MailFolderItems.hanldeCheckboxChange: selected_items " + this.state.selected_items)
  }

  handleDeleteCLick = async e => {
    console.log("MailFolderItems.handleDeleteCLick " + this.state.selected_items)
    this.moveMessage("trash")
  }

  fetchFolderItems() {
    console.log("MailFolderItems.fetchFolderItems")
    const token = sessionStorage.getItem('token');
    const request = {
      "action": "get-folder-items",
      "token": token,
      "folder": this.state.folder,
      "mailbox": this.state.mailbox
    }
    fetch(EMAIL_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(data => {
          console.log("MailFolderItems.fetchFolderItems: data " + data);
          return data.json()
      }).then(json => {
        // const json_body = JSON.parse(json.body)
        console.log("MailFolderItems.fetchFolderItems", json)
        this.setState({mail_items: json})
        // console.log("MailFolders.MailBgetFoldersoxInfo: set boxInfo" + json.body)
        return json
      }).catch( err => {
        console.log("MailFolderItems.fetchFolderItems: Error: " + err)
      });
  }

  getFolderItems() {
    console.log("Starting MailFolder.getFolderItems: " + this.state.folder)
    const folder = this.state.folder
    const info = this.state.mail_items;
  
    if (info !== null && info['items'] !== undefined) {
      var rows = []
      var hasMail = false
      rows.push(info['items']
        .map((item, i) => {
        hasMail = true
        return(
          <MailMsgRow key={item.messageId}
                      item={item} 
                      folder={folder} 
                      checkboxFunction={() => this.hanldeCheckboxChange}
                      mailClick={(a,b) => this.messageRef.current.handleMailClick(a,b)}
                      />
        )
      }));
  
      if (!hasMail) {
        console.log("MailFolderItems.getFolderItems: no rows")
        return this.getNoMessages(folder)
      }
      
      console.log("MailFolderItems.getFolderItems: has mail")
      return (
        <tbody key={folder} >
          {rows}
        </tbody>
      );
    } else {
      console.log("MailFolderItems.getFolderItems: emailInfo undefined")
      return this.getNoMessages(folder)
    }
  }

  selectAll() {
    console.log("Starting MailFolderItems.selectAll")
    var checkboxes = document.querySelectorAll('input[type="checkbox"]');
    let checked = false
    var selected = this.state.selected_items
    for (var i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i].id !== "selectAllCheckbox") {
        // console.log("MailFolderItems.selectAll: " + checkboxes[i].value )
        checkboxes[i].checked = checked
        if (checked) {
          console.log("MailFolderItems.selectAll: adding " + checkboxes[i].value)
          selected.push(checkboxes[i].value)
        } else {
          var index = selected.indexOf(checkboxes[i].value)
          console.log("MailFolderItems.selectAll: value index " + index)
          if (index !== -1) {
            console.log("MailFolderItems.selectAll: splicing " + index)
            selected.splice(index, 1);
          }
        }    
      } else {
        console.log("MailFolderItems.selectAll: " +  checkboxes[i].checked)
        checked = checkboxes[i].checked
      }
    }
    this.setState({selected_items:selected})
  }

  handleMoveChange(folder){
    // const folder = e.target.value
    console.log("Starting MailFolderItems.handleMoveChange " + folder)
    const select = document.getElementById("moveTo")
    select.value = ""
    this.moveMessage(folder)
  }

  onKeyDown(e) {
    console.log("Starting MailFolderItems.onKeyDown " + e.key)
    const val = document.getElementById("moveTo").value
    console.log("MailFolderItems.onKeyDown " + val)
    if (e.key === "Unidentified" || e.key === "Enter") {
      // console.log("MailFolderItems.onKeyDown: " + val )
      this.handleMoveChange(val)
    }
  }

  render() {
    console.log("Starting render")
    const {folder, loading, mail_items} = this.state
    if (loading || mail_items === null ) {
      return <div>
        <ResetTimeout />
        <Roller />
      </div>
    } else {
      // const items = 
      var note = <div></div>
      if (folder === "spam" || 
          folder === "trash" || 
          folder === "quarantined")
      {
        note = <div className="folder-note" >Please note items in this folder will deleted after 7 days</div>
      }

      let options = []
      // options.push(<option value="">Move To</option>)
      options.push(
        this.state.folders.map((item, i) => {
          return <option key={item} style={{textTransform: 'capitalize'}}>{item}</option>
        })
      );
      const folders = <span style={{padding: "5px"}}>
          <input
              key="moveTo"
              className="moveTo"
              id="moveTo"
              list="folders"
              style={{textTransform: 'capitalize'}}
              onKeyUp={e => this.onKeyDown(e)}
              // on_Change={e => this.handleMoveChange(e)}
              placeholder="-- Move To --"/>
          <datalist id="folders" className="moveTo" >{options}</datalist>
        </span>
      
      return (
        <div className='mailbox-items'>
          <MailMessage 
            ref={this.messageRef}
            composeTo={this.composeTo}
            composeCc={this.composeCc}
            composeSubject={this.composeSubject}
            composeSetSelectedFile={this.composeSetSelectedFile}
            />
          <div className="folder-note">
            <input type="checkbox" id="selectAllCheckbox" onChange={() => this.selectAll(this)}/>
            &nbsp;
            <button
              className="login-btn" 
              onClick={this.handleDeleteCLick}>Delete</button>
            &nbsp;
            {folders}
            {note}
          </div>
          <table width="100%" valign="top" key={this.state.mail_items} >
            {this.getFolderItems()}
          </table>
        </div>
      )
    }
  }
}