import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import { MFA_ENDPOINT } from './api-backend';
import useAuth from './useAuth';
import Cookies from 'universal-cookie';
import Roller from '../roller/Roller';

async function verifyMFA(request) {
  return fetch(MFA_ENDPOINT, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
}

export default function VerifyMFA({setAuth}) {
  const [code, setCode] = useState();
  const [rememberMe, setRememberMe] = useState();
  //const [password, setPassword] = useState();
  const { token } = useAuth();
  const [message, setMessage] = useState();
  const [loading, setLoading] = useState(false);
  
  const handleSubmit = async e => {
    console.log("handleSubmit " + code + " " + rememberMe)
    e.preventDefault();
    setMessage("Verifying")
    if (code === undefined) {
      setMessage("Invalid code")
      return
    }
    setLoading(true)
    
    let deviceId = undefined
    if (rememberMe !== undefined && rememberMe) {
      console.log("send remember me cookie")
      const cookies = new Cookies();
      deviceId = cookies.get('rememberme')
    }
    
    console.log("handleSubmit " + code + " " + rememberMe + " " + deviceId)
    
    const loginResp = await verifyMFA({
      "action": "verify",
      "token": token,
      code,
      "deviceId": deviceId
    });
    console.log(loginResp)
    //const resp = JSON.parse(loginResp)
    console.log("verified: " + loginResp.verified)
    if (loginResp.verified) {
      setAuth(loginResp.verified);
      window.location = "./user"
    } else {
      setMessage("Invalid code")
    }
    setLoading(false)
  }

  return(
    <div className="App ">
      <h1>2-Factor Authentication</h1>
      <div className="login-invalid"><b>{message}</b></div>
      <form onSubmit={handleSubmit} >
        <label>
          <p>Code</p>
          <input type="text" onChange={e => setCode(e.target.value)}/>
        </label>
        <label>
          <p>
            <input type="checkbox" onChange={e => setRememberMe(e.target.checked)}/>
            Remember this device
          </p>
        </label>
        <div style={{padding:"5px"}}>
          <button className="login-btn" type="submit">Submit</button>
        </div>
      </form>
      {loading ? <Roller key={loading} display={loading}/> : null}
    </div>
  )
}

VerifyMFA.propTypes = {
  setAuth: PropTypes.func.isRequired
};