import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from "react-router-dom";

export default class Media extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTime: localStorage.getItem("currentTime")
    }
  }

  componentDidMount = () => {
    const { currentTime } = this.state
    console.log("currentTime " + currentTime)
    // this.playVideo();
  };

  componentWillUnmount = () => {
    // this.pauseVideo();
  };

  playVideo() {
    let currentTime = localStorage.getItem("currentTime")
    if (currentTime === undefined) {
      currentTime = 0
      localStorage.setItem("currentTime", currentTime)
    }
    const video = document.getElementById("videoRef")
    video.currentTime = currentTime
    video.play()
      .then(function () {
        console.log("playback started!")
      })
      .catch(function (error) {
        console.log("playback failed! " + error)
      });
  };

  pauseVideo() {
    // Pause as well
    const video = document.getElementById("videoRef")
    video.pause();
    const cTime = video.currentTime
    localStorage.setItem("currentTime", cTime)
    console.log("playback paused!" + cTime)
  };

  render() {
    // const { email, passwordSet, token, verification } = this.state;

    return <div>
      <Helmet>
        <title>Media | Elliott Technologies</title>
        <meta name="keywords" content="arvada,denver,colorado,cloud,web,applications,network,design,integration" />
      </Helmet>
      <div>
        <Link to="/movies" >Movies</Link>
      </div>
      <div>
        <Link to="/music" >Music</Link>
      </div>
    </div>
  };
}
