function TimeoutModal(props) {
  return (
        <div className="modal-content">
        <p>
        You have been logged off due to in activity
        </p>
        <button onClick={props.onClick}>
          Close
        </button>
        </div>
  );
}
export default TimeoutModal;