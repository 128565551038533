import React from 'react';
import { AUTH_ENDPOINT } from '../api-backend'
import PasswordPolicy from '../../password/PasswordPolicy';
import Roller from '../../roller/Roller';
import StrengthMeter from '../../password/StrenghtMeter';

async function storePassword(body) {
  console.log("Settings storePassword")
  const storeResp = fetch(AUTH_ENDPOINT, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(data => {return data.json()})
      .catch(error =>(
        console.log(error)
      ))
  // await storeResp
  console.log(storeResp)
  return storeResp
}

export default class Settings extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordConfirm: null,
      loading: false,
    }
    this.policyRef = React.createRef()
  }

  componentDidMount() {
    console.log("Settings.componentDidMount")
    const token = sessionStorage.getItem('token');
    if (token === undefined || token === null || token === "") {
      window.location = "./"
      return 
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Settings.componentDidUpdate")
  }

  checkPasswords() {
    const { password, passwordConfirm } = this.state;
    if (passwordConfirm !== null && password === passwordConfirm) {
      return true
    }
    return false
  }

  divCheckPasswords() {
    if (this.checkPasswords()) {
      return <div className="check-passed">&#10003;</div>
    }
    return <div className="check-failed">X</div>
  }

  async handleChangeClick() {
    console.log("Settings.handleChangeClick")
    this.setState({'loading': true})

    const { password } = this.state;
    const token = sessionStorage.getItem('token');

    const payload = {
      "method": "update-token",
      "token": token,
      "password":password
    }
    console.log("Settings.handleChangeClick storing - " + JSON.stringify(payload))
    const storeResp = await storePassword(payload);
    console.log("Settings.handleChangeClick storeResp " + JSON.stringify(storeResp))
    this.setState({'password':''})
    this.setState({'passwordConfirm':null})
    this.setState({'loading': false})
  }

  render () {
    console.log("Settings.render")

    const { loading, password, passwordConfirm } = this.state;
    const key=loading+Math.random
    return <div key={key}>
          <h2>Settings</h2>
          <table border={'1px'} width='100%' cellPadding='10px'>
            <tbody>
              <tr>
                <td valign='top' width='100px'>
                  Change Password
                </td>
                <td align='center' width='90%'>
                <table>
                  <tbody>
                    <tr>
                    <td>
                      Password
                    </td>
                    <td>
                      <input autoComplete="new-password" type="password" onChange={e => this.setState({ password: e.target.value })}/>
                      <StrengthMeter
                        password={password}
                      />
                    </td>
                    </tr>
                    <tr>
                    <td>
                      Confirmation
                    </td>
                    <td>
                      <input autoComplete="new-password" type="password" onChange={e => this.setState({ passwordConfirm: e.target.value })}/>
                    </td>
                    </tr>
                  </tbody>
                </table>
                <div style={{padding: "5px"}}>
                  <button id="sign-up-btn" 
                    className="login-btn" 
                    onClick={() => this.handleChangeClick()}>Change</button>
                </div>
                <br />

                <PasswordPolicy 
                  password={password}
                  passwordConfirm={passwordConfirm}
                  ref={this.policyRef}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          {loading ? <Roller /> : null}
        </div>
  }
}

