import React from 'react';
import PropTypes from 'prop-types';
import MailCompose from './MailCompose'
import MailFolderItems from './MailFolderItems'
import { EMAIL_ENDPOINT } from './api-backend';
import './MailItem.css';
import MailFolder from './MailFolder';
import Roller from '../roller/Roller';

export default class MailFolders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailbox: props.mailbox,
      displayName: props.displayName,
      current_folder: null,
      folders: null
    };

    this.handleFolderClick = this.handleFolderClick.bind(this)
    this.composeRef = React.createRef()
  }

  componentDidMount() {
    console.log("MailFolders.componentDidMount")
    console.log("MailFolders.componentDidMount: mailbox " + this.state.mailbox)
    if (this.state.current_folder === null)  {
      this.setState({current_folder:"inbox"})
    }
    console.log("MailFolders.componentDidMount: current_folder " + this.state.current_folder)
    if (this.state.folders === null)  {
      this.getFolders()
    }
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("MailFolders.componentDidUpdate: message " + this.state.message)
    console.log("MailFolders.componentDidUpdate: current_folder " + this.state.current_folder)
  }


  getFolders() {
    console.log("MailFolders.getFolders")
    const token = sessionStorage.getItem('token');
    const request = {
      "action": "get-folders",
      "token" : token,
      "mailbox": this.state.mailbox
    }
    fetch(EMAIL_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(data => {
        console.log("MailFolders.getFolders: data " + data);
        return data.json()
      }).then(json => {
        // const json_body = json
        console.log("MailFolders.getFolders: json", json)
        const json_folders = json.folders
        console.log("MailFolders.getFolders: folders", json_folders)
        // console.log("setting boxInfo")
        this.setState({folders : json_folders})
        console.log("MailFolders.getFolders: status", json.statusCode)
        // console.log("MailFolders.MailBgetFoldersoxInfo: set boxInfo" + json.body)
        return json_folders
      }).catch( err => {console.log("MailFolders.getFolders: Error: " + err)});

  }


  handleFolderClick = async e => {
    console.log("click " + e);
    console.log("MailFolders.handleFolderClick: " + e)
    this.setState({current_folder: e})
  } 

  
  render () {
    const { current_folder, displayName, folders, mailbox } = this.state
    if (current_folder === null || folders === null ) {
      return (
        <Roller />
      )
    }
    else
    {
      return(
        <div>
          <table className="mail-table" align='center'>
            <tbody>
              <tr>
                <td width="100px" valign="top">
                  <MailCompose 
                    from={mailbox}
                    displayName={displayName}
                    ref={this.composeRef}
                    />
                  {folders.map((item, key) => {
                      return <MailFolder
                              key={current_folder + item}
                              current_folder={current_folder}
                              folder={item}
                              folderClick={() => this.handleFolderClick}
                              />
                  })}
                </td>
                <td valign="top" key={this.state.current_folder} >
                  <MailFolderItems
                      folder={current_folder} 
                      folders={folders} 
                      mailbox={mailbox}
                      composeTo={e => this.composeRef.current.handleToChange(e)}
                      composeCc={e => this.composeRef.current.handleCcChange(e)}
                      composeSubject={e => this.composeRef.current.handleSubjectChange(e)}
                      composeSetSelectedFile={e => this.composeRef.current.setSelectedFile(e)}
                      />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )
    }
  };
}

MailFolders.propTypes = {
  mailbox: PropTypes.string.isRequired
};