import React from 'react';
import { DEVICE_ID_HEADEER, MEDIA_URL_ENDPOINT } from '../components/api-backend';

export default class AudioTrack extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      source: props.source,
      track: props.track,
      bgColor: 'black'
      // loading: true
    }
    this.playerRef = props.playerRef
    this.playTrack = this.playTrack.bind(this)
    this.disable = this.disable.bind(this)
  }

  componentDidMount() {
    // const {id} = this.state
    // console.log("AudioTrack.componentDidMount - id " + id)
  };

  componentWillUnmount() {
  };
  
  disable(){
    this.setState({bgColor:'gray'})
  }

  playTrack() {
    const {id, source} = this.state
    console.log("AudioTrack.playTrack")
    this.setState({bgColor:'#6fd873'})
    const deviceId = localStorage.getItem(DEVICE_ID_HEADEER)
    var headers = {
      'Content-Type': 'application/json'
    }
    if (deviceId !== null) {
      headers = {
        'Content-Type': 'application/json',
        'X-Elliott-Technologies-Device-Id': deviceId
      }
    }
    fetch(MEDIA_URL_ENDPOINT + '?key=' + source, {
        headers: headers
      }).then(response => {
          if (response.status === 401) {
            sessionStorage.clear()
            window.location = "./media-auth"
          }
          return response.json()
        })
        .then(json => {
          this.setState({movies: json.videos})
          console.log("AudioTrack.playTrack - json", json)
          this.setState({loading:false})
          
          this.playerRef.current.setSource(json.url)
          this.playerRef.current.setTrackId(id)
          this.playerRef.current.playTrack()
          console.log("AudioTrack.playTrack - done")
          })
        .catch( err => {
          console.log("AudioTrack.playTrack - error " + err)
          this.setState({loading:false})
        }); 
  };

  render() {
    const { id, track , bgColor} = this.state;
    //console.log("AudioTrack.render - id/bgColor " + id + "/" +bgColor)
          
    return  <tr style={{'backgroundColor':bgColor}}>
        <td>
        {id}
        </td>
        <td>
        {track.artist}
        </td>
        <td>
        {track.album}
        </td>
        <td>
        {track.name}
        </td>
        <td>
        <button onClick={() => this.playTrack()}>
          Play!
        </button>
        </td>
      </tr>
  };
}
