import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';

const lower = RegExp("(?=.*[a-z])")
const upper = RegExp("(?=.*[A-Z])")
const numeric = RegExp("(?=.*[0-9])")
const length = RegExp("^.{8,}$")
const special = RegExp("(?=.*[\\-\\[\\]\"'~`_.,<>/;:{}+=\\|@#^()$!%*?&\\\\])")

class PasswordPolicy extends Component {

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'Weak';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'Weak';
    }
  }

  checkRegExp(regex, value) {
    if (regex.test(value)) {
      return <div className="check-passed">&#10003;</div>
    }
    return <div className="check-failed">X</div>
  }

  checkTestedResult(score){
    if (score >= 2) {
      return <div className="check-passed">&#10003;</div>
    }
    return <div className="check-failed">X</div>
  }

  checkPasswords() {
    const { password, passwordConfirm } = this.props;
    if (passwordConfirm !== null && password === passwordConfirm) {
      return true
    }
    return false
  }

  divCheckPasswords() {
    if (this.checkPasswords()) {
      return <div className="check-passed">&#10003;</div>
    }
    return <div className="check-failed">X</div>
  }

  isCompliant(password) {
    const testedResult = zxcvbn(password);

    if (lower.test(password) 
        && upper.test(password) 
        && numeric.test(password) 
        && length.test(password)
        && special.test(password)
        && testedResult.score >= 2
        ) {
        // console.log("PasswordPolicy.isCompliant - true")
        return true
    }
    return false
  }
  
  render() {
    // console.log("PasswordPolicy.render")
    const { password } = this.props;
    if (password === undefined)
      return <div style={{border: '1px solid red', padding:'10px'}}>Please pass the 'password' property to PasswordPolicy component</div>

    const testedResult = zxcvbn(password);
    
    return (
      <table className="check-table" align='center'><tbody>
      <tr>
        <td>{ this.checkTestedResult(testedResult.score) }</td>
        <td style={{textAlign: "left"}}>
          <strong>Password strength:</strong> {this.createPasswordLabel(testedResult)}
        </td>
      </tr>
      <tr>
        <td>{ this.checkRegExp(length, password) }</td>
        <td style={{textAlign: "left"}}>
          Must be at least 8 characters in length.<br />
        </td>
      </tr>
      <tr>
        <td>{ this.checkRegExp(lower, password) }</td>
        <td style={{textAlign: "left"}}>
          Must contain at least one lowercase alphabetical character.
        </td>
      </tr>
      <tr>
        <td>{ this.checkRegExp(upper, password) }</td>
        <td style={{textAlign: "left"}}>
          Must contain at least one uppercase alphabetical character. <br />
        </td>
      </tr>
      <tr>
        <td>{ this.checkRegExp(numeric, password) }</td>
        <td style={{textAlign: "left"}}>
          Must contain at least one numeric value.<br />
        </td>
      </tr>
      <tr>
        <td>{ this.checkRegExp(special, password) }</td>
        <td style={{textAlign: "left"}}>
          Must contain at least one special character.<br />
        </td>
      </tr>
      <tr>
        <td>{ this.divCheckPasswords() }</td>
        <td style={{textAlign: "left"}}>
          Password and confirm must match.
        </td>
      </tr>
      </tbody></table>
    );
  }
}

export default PasswordPolicy;