import React from 'react';
import './MoviePlayer.css'

export default class AudioPlayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      source: "null",
      time: 0,
      // loading: true
    }
    this.nextTrack = props.nextTrack;
  }

  componentDidMount() {
    console.log("AudioPlayer - componentDidMount")
    // this.playVideo();
  };

  componentWillUnmount() {
    // this.pauseVideo();
  };

  setSource(source) {
    console.log("AudioPlayer - source " + source)
    this.setState({"source":source})
    const video = document.getElementById("musicRef")
    video.src = source
    video.load()
  }

  setTrackId(id) {
    console.log("AudioPlayer - trackId " + id)
    this.setState({"trackId": id})
  }

  playTrack() {
    console.log("AudioPlayer.playTrack")
    const audio = document.getElementById("musicRef")
    if (audio === null) {
      console.log("AudioPlayer.playTrack - musicRef is null")
      return
    }

    audio.addEventListener('error', () => {
      console.log('Error code: ' + audio.error.code);
      console.log('Error message: ' + audio.error.message);
    });
    audio.play()
      .then(function() {
        console.log("AudioPlayer.playTrack - playback started!")
      })
      .catch(function(error) {
        console.log("AudioPlayer.playTrack - playback failed! " + error)
      });
    console.log("AudioPlayer.playTrack - done")
  };

  pauseVideo() {
    // Pause as well
    const video = document.getElementById("musicRef")
    if (video === null)
      return
    video.pause();
    const cTime = video.currentTime
    localStorage.setItem("currentTime", cTime)
    console.log("AudioPlayer - playback paused!" + cTime)
  };
  
  trackEnded() {
    console.log("AudioPlayer - trackEnded!")
    const {trackId} = this.state
    this.nextTrack(trackId)
  }

  render() {
    const { source, trackId } = this.state;
    // if ( source === null || source === "null" ){
    //   return <div>No player, yet</div>
    // }
    return <div>
        <div id="player-frame">
        {trackId}
          <span className='video-player-close'>&gt;</span>
          <span className='video-player-close'>||</span>
          <audio id="musicRef" controls 
            controlsList="nodownload"
            preload="none"
            onPause={this.pauseVideo}
            onPlay={() => this.playTrack()} 
            onEnded={() => this.trackEnded()} 
            >
            <source 
              src={source}
              type='audio/mpeg' /> 
          </audio>
        </div>
      </div>
  };
}
