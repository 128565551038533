import React from 'react';
import './EmailAddress.css';
import Address from './Address';
import { appendIds } from './ContactModal'
export default class Addresses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: props.editable,
            addresses: props.addresses,
            count: 0,
            elem_list: [],
            myRefs: [],
        }
    }

    componentDidMount() {
        console.log("Addresses.componentDidMount *********")
        const { addresses, editable } = this.state
        if (editable === undefined) {
            this.setState({ editable: false })
        }
        if (addresses === undefined) {
            this.setState({addresses:[]})
        }
        appendIds(addresses)
        this.generateList(addresses)
        this.setState({'addresses':[]})
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("Addresses.componentDidUpdate")        
    }

    addNew(){
        const {count} = this.state
        console.log("Addresses.addNew ", count)
        const array = this.getAddresses()
        array.push({})
        appendIds(array)
        this.generateList(array)
    }

    delete(id) {
        console.log("delete ", id)
        const tmp = this.getAddresses()
        appendIds(tmp)
        const array = tmp.filter(function(item) {
            return item.id !== id}
        )        
        appendIds(array)
        this.generateList(array)
    }

    generateList(array){
        this.setState({address_list: []})
        const {editable} = this.state
        console.log("Addresses.generateList " + JSON.stringify(array))
        let elem_list = []
        let ref_list = []
        if (array !== undefined && array !== null) {
            array.forEach(item => {
                const aRef = React.createRef()
                const key = "address" + item.id + item.address + item.type
                const elem = <div id='no-padding' key={key} className="email-address">
                    <Address
                        id={item.id}
                        address={item.address}
                        editable={editable}
                        type={item.type}
                        ref={aRef}
                    />
                    &nbsp;
                    <span id='delete-item' title='delete'
                        onClick={e => this.delete(item.id)}>
                        X
                    </span>
                </div>
                ref_list.push(aRef)
                elem_list.push(elem)
            })
        }
        this.setState({'elem_list': elem_list})
        this.setState({'count': elem_list.length})
        this.setState({'myRefs': ref_list})
    }

    getAddresses(){
        const { myRefs } = this.state
        let array = []
        if (myRefs !== undefined) {
            myRefs.forEach(ref => {
                array.push(
                    ref.current.get()
                )
            })
        }
        return array
    }

    render() {
        console.log("Addresses.render")
        const {elem_list} = this.state
        return <div id="no-padding">
                {elem_list}
                <div id="no-padding">
                    <button title='add' onClick={e => this.addNew(e)}>+</button>
                </div>
            </div>
    }
}  