import { useState } from 'react';

export default function useAuth() {
    
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    // console.log("token: " + tokenString);
    const userToken = tokenString;
    //return userToken?.token;
    return userToken;
  };

  const isAuthenticated = () => {
    const auth = sessionStorage.getItem('auth');
    return auth;
  };

//const [token, setToken] = useState(getToken());
  const [token, setToken] = useState(getToken());
  const [auth, setAuth] = useState(isAuthenticated());

  const saveAuth = authValue => {
    sessionStorage.setItem('auth', authValue);
    setAuth(authValue)
  }
      
  const saveToken = userToken => {
    sessionStorage.setItem('token', userToken);
    setToken(userToken);
  };

  return {
    token,
    auth,
    setToken: saveToken,
    setAuth: saveAuth
  };

};
