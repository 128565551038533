import React, { Component } from 'react';
import zxcvbn from 'zxcvbn';
import './StrengthMeter.css';

class StrengthMeter extends Component {

  createPasswordLabel = (result) => {
    switch (result.score) {
      case 0:
        return 'None';
      case 1:
        return 'Weak';
      case 2:
        return 'Fair';
      case 3:
        return 'Good';
      case 4:
        return 'Strong';
      default:
        return 'None';
    }
  }

   
  render() {
    const { password } = this.props;
    if (password === undefined) {
      return <div style={{border: '1px solid red', padding:'10px'}}>
        StrengthMeter missing the required 'password' property
        </div>
    }

    const testedResult = zxcvbn(password);

    return (
      <div className="password-strength-meter" data-testid="strength-meter">
        <div
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(testedResult)}`}
          value={testedResult.score}
          // max="4"
        />
      </div>
    );
  }
}

export default StrengthMeter;