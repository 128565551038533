import { createSlice } from '@reduxjs/toolkit'

const timeoutInterval = 300;

export const timeoutSlice = createSlice({
  name: 'timeout',
  initialState: {
    value: timeoutInterval,
  },
  reducers: {
    increment: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value += 1
    },
    decrement: (state) => {
      state.value -= 1
    },
    resetTimeout: (state) => {
      // console.log("timeoutSlice.resetTimeout")
      state.value = timeoutInterval
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, resetTimeout } = timeoutSlice.actions

export default timeoutSlice.reducer