import React from 'react';
// import EmailValidator from 'email-validator'
import { AUTH_ENDPOINT } from '../components/api-backend'
import Roller from '../roller/Roller';

async function verify(body) {
  console.log("SignUpVerify sendMail")
  const sendMailResp = fetch(AUTH_ENDPOINT, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(response => {
        const json = response.json()
        console.log(json)
        return json
      })
      // .then(data => data.json())
      .catch(error =>(
        console.log(error)
      ))
  console.log(sendMailResp)
  return sendMailResp
}

export default class SignUpVerify extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      code: null,
      email: props.email,
      message: null,
      loading: false
    }

    this.setVerification = props.setVerification
  }

  componentDidMount() {
    console.log("SignUpVerify.componentDidMount")
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("SignUpVerify.componentDidUpdate")
  }

  async handleVerificationClick(){
    console.log("SignUpVerify.handleSendClick ")
    this.setState({'loading':true})
    const { code, email } = this.state;
    const payload = {
      "method": "verify_registration",
      "email": email,
      "verification_code": Number(code)
    }
    console.log("SignUpVerify.handleSendClick storing - " + JSON.stringify(payload))
    const storeResp = await verify(payload);

    try {
      // const jsonResp = JSON.parse(storeResp)
      if (storeResp.verified) {
        this.setVerification(storeResp.token)
      }
    } catch(error) {
      console.log("SignUpVerify.handleSendClick error " + error)      
    }
    console.log("SignUpVerify.handleSendClick not verified")
    this.setState({message: "Invalid code"})
    this.setState({'loading':false})
  }

  render() {
    const { loading, message } = this.state;
    
    return <div><h2>Enter Verification Code</h2>
      <div className="warning-message"><b>{message}</b></div>
      <p>
      <input type="text" onChange={e => this.setState({code: e.target.value})}/>
      </p>
      <button className="login-btn" 
            onClick={() => this.handleVerificationClick()}>Verify</button>
      {loading ? <Roller /> : null}
    </div>
  }
}