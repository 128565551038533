import React from 'react';
import './Contacts.css';
import { CONTACTS_ENDPOINT } from '../components/api-backend';

export default class ContactsLoader extends React.Component {

  constructor() {
    super();
    this.state = {
      loading: false,
    }
  }

  componentDidMount() {
    console.log("ContactsLoader.componentDidMount")
    const contacts = sessionStorage.getItem('contacts')
    if (contacts === null) { 
      this.load()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("ContactsLoader.componentDidUpdate")
  }

  async reload(){
    let response = this.load()
    await response
  }

  async storeEmailAddresses(json){
    let email_options = []
    json.forEach(contact => {
      if (contact.email_addresses !== null) {
        contact.email_addresses.forEach(email => {
          email_options.push(
            contact.name + " <" + email.email_address + ">"
          );  
        });
      }
    });
    sessionStorage.setItem("emails", email_options)
  }

  async load() {
    console.log("ContactsLoader.load")
    // sessionStorage.setItem('contacts', null)
    this.setState({loading:true})
    const token = sessionStorage.getItem('token');
    if (token === undefined || token === null) {
      window.location = "./"
      return 
    }
    const request = {
        "action": "get",
        "token" : token
    }
    await fetch(CONTACTS_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(response => {
          if (response.status === 401) {
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('auth')
            window.location = "./"
          }
          return response.json()
        })
        .then(json => {
          // console.log("Contacts.load: " + json.statusCode)
          
          sessionStorage.setItem("contacts", JSON.stringify(json))
          // this.setState({contacts: json})
          console.log("ContactsLoader.load json", json)
          this.storeEmailAddresses(json)
          // this.setState({loading:true})
          this.setState({loading:false})
          return json
          })
        .catch( err => {
          console.log("ContactsLoader.load: Error " + err)
          this.setState({loading:false})
        });
  }

  render() {
    console.log("ContactsLoader.render")
    return <div></div>
  }
}
