import React from 'react';
import AudioPlayer from './AudioPlayer';
import AudioTrack from './AudioTrack';
import Roller from '../roller/Roller';
import { DEVICE_ID_HEADEER, MUSIC_ENDPOINT } from '../components/api-backend';

export default class Audio extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tracks: null,
      playlist: null,
      playlistRefs: []
    }
    this.playerRef = React.createRef()
    this.nextTrack = this.nextTrack.bind(this)
  }

  componentDidMount = () => {
    this.load()
  };

  componentWillUnmount = () => {

  };

  buildList(tracks){
          let listRefs = []
          let list = []
          var cnt = 0
          tracks.forEach(item => {
            console.log("track " + cnt + " " + JSON.stringify(item))
            const key = item.key + cnt
            const listRef = React.createRef()
            list.push(
              <AudioTrack
                key={key}
                id={cnt}
                track={item}
                source={encodeURIComponent(item.key)}
                playerRef={this.playerRef}
                ref={listRef}
              />
            )
            listRefs.push(listRef)
            cnt++
          })
          this.setState({playlist:list})
          this.setState({playlistRefs:listRefs})
  }

  async load() {
    console.log("Movies.load")
    this.setState({loading:true})
    const deviceId = localStorage.getItem(DEVICE_ID_HEADEER)
    var headers = {
      'Content-Type': 'application/json'
    }
    if (deviceId !== null) {
      headers = {
        'Content-Type': 'application/json',
        'X-Elliott-Technologies-Device-Id': deviceId
      }
    }
    console.log("Movies.load - headers " + JSON.stringify(headers))
    await fetch(MUSIC_ENDPOINT, {
        headers: headers
      }).then(response => {
          console.log("Movies.load - response " + response)
          if (response.status === 401) {
            sessionStorage.clear()
            window.location = "./media-auth"
          }
          return response.json()
        })
        .then(json => {
          // console.log("Contacts.load: " + json.statusCode)
          //sessionStorage.setItem("contacts", JSON.stringify(json))
          let vids = json.music
          if (vids === undefined) {
            vids = [{'key':'na','name':'No audio content found'}]
          }
          this.shuffleIt(vids)
          this.setState({tracks:vids})
          this.buildList(vids)
          
          console.log("Movies.load json", json)
          this.setState({loading:false})
          return json
          })
        .catch( err => {
          console.log("Movies.load: Error " + err)
          this.setState({loading:false})
        });
  }

  nextTrack(currentTrack) {
    console.log("Audio.nextTrack - current track" + currentTrack)
    const { playlistRefs  } = this.state;
    const last_track = playlistRefs[currentTrack]
    last_track.current.disable()
    const track = playlistRefs[++currentTrack]
    console.log(track)
    track.current.playTrack()
  }
  
  shuffleIt(array) {
    let currentIndex = array.length,  randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex > 0) {

      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
  
  shuffle() {
    const { tracks  } = this.state;
    this.shuffleIt(tracks)
    tracks.forEach(track => {
      console.log(track)
    })
    this.buildList(tracks)
  }

  genre(val) {
    console.log("filtering genre [" + val+ "]")
    const { tracks  } = this.state;
    let genreTracks = []
    tracks.forEach(track => {
      console.log("genre [" + track.genre + "]")
      if ( track.genre === val){
        console.log("found" + track)
        genreTracks.push(track)
      }
    })
    // this.shuffleIt(genreTracks)
    // this.setState({tracks:genreTracks})
    this.buildList(genreTracks)
  }

  render() {
    const { loading, playlist } = this.state;
    if (loading || playlist === null || playlist === "null" || playlist === undefined) {
      return (
        <Roller key={loading} display={loading}/>
      )
    }

    return  <div style={{'textAlign':'left','backgroundColor':'black','color':'white'}}>
      <button onClick={ () => this.shuffle()} >shuffle</button>
      <button onClick={ () => this.nextTrack(0)} >test</button>
      <button onClick={ () => this.genre("Christmas")} >Christmas</button>
      <AudioPlayer ref={this.playerRef} nextTrack={(id) => this.nextTrack(id)}/>
      <table>
        <tbody>
          {playlist}
        </tbody>
      </table>
      </div>
  };
}
