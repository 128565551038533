import React from 'react';
import { CONTACTS_ENDPOINT } from '../components/api-backend';
import './UploadModal.css'
import Roller from '../roller/Roller';

const loadFile = src =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(src)
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })  
;

const FileUploader = ({onFileSelectSuccess, onFileSelectError}) => {
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (file.size > 1024*1024*10)
    onFileSelectError({ error: "File size cannot exceed more than 10MB" });
    else onFileSelectSuccess(file);
  }

  return (
    <div className="file-uploader">
      <input type="file" id="compose-file" onChange={handleFileInput} />
    </div>
  )
}

export default class UploadModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        selectedFile: null,
        showModal: false,
        replace: false,
    }
    this.handleModalClose = this.handleModalClose.bind(this)
  }

  componentDidMount() {
    console.log("UploadModal.componentDidMount")
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("UploadModal.componentDidUpdate")
  }

  handleModalClose = async e => {
    console.log("UploadModal.handleModalClose")
    this.setState({showModal:false})
  }

  async import(data) {
    const { replace } = this.state
    const token = sessionStorage.getItem('token');
    const request = {
      "action": "import",
      "token" : token,
      "replace" : replace,
      "file" : data
    }
    
    await fetch(CONTACTS_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
      .then(response => {
        console.log("UploadModal.import: response: " + response);
        return response.json()
      })
      .then(json => {
        console.log("UploadModal.import: json: " + json);
        return json
      }).then(data => {
        console.log("UploadModal.import: data: " + data);
        return data
      })
      .catch( err => {
        console.log("UploadModal.import: Error: " + err)
      });
  }

  async handleUpload(e){
    console.log("UploadModal.handleUpload")
    this.setState({loading:true})

    const { selectedFile } = this.state
    
    var data = null
    if (selectedFile !== null) {
      // console.log("Upload.handleUpload selectedFile " + selectedFile)
      await loadFile(selectedFile)
        .then(result =>{
        console.log(result)
        data = result
      })
    }
    console.log("UploadModal.handleUpload data " + data)

    const response = this.import(data)
    await response
    console.log("UploadModal.handleUpload response " + response)

    this.setState({loading:false})
    this.setState({showModal:false})

    window.location = "./contacts"
  }

  setSelectedFile(e) { 
    console.log("UploadModal.setSelectedFile: " + e)
    this.setState({selectedFile: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }

  showModal = async e => {
    console.log("UploadModal.showModal")
    this.setState({showModal:true})
  }

  handleReplaceChange = async e => {
    this.setState({replace:e.target.checked})
  }

  render(){
    const {loading, showModal} = this.state
    if (!showModal) {
      return <div></div>
    }
    return(
        <div className='contacts-upload' id='contacts-upload-modal'>
            <div className="contacts-upload-modal-content">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td><h2>Upload Contacts</h2></td>
                                <td align='right' width="3px">
                                    <div id='modal-close' onClick={this.handleModalClose}>X</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div id="compose-error"></div>
                    <div>Please choose a csv file that's either in google or outlook format.</div>
                    <FileUploader id="uploader"
                        onFileSelectSuccess={(file) => this.setSelectedFile(file)}
                        onFileSelectError={({ error }) => alert(error)}
                        />
                    <div>
                      <input type="checkbox" onChange={this.handleReplaceChange}/>
                      Replace All
                    </div>
                    <div id="upload-button" onClick={e => this.handleUpload(e)}>Upload</div>
                </div>
            </div>
            { loading ? <Roller /> : null }
        </div>
    )
  }
}  