import React from 'react';
import { CONTACTS_ENDPOINT } from '../components/api-backend';
import './UploadModal.css'
import Addresses from './Addresses';
import EmailAddresses from './EmailAddresses'
import PhoneNumbers from './PhoneNumbers';
import Roller from '../roller/Roller';

export function appendIds(array) {
  // console.log("ContactModal.appendIds ", JSON.stringify(array))
  let cnt = 0 
  try {
    array.forEach(item => {
      item["id"] = cnt
      // console.log(item)
      cnt = cnt + 1
    })
  } catch(error) {
    console.log("ContactModal.appendIds " + error)
  }
}

export function removeIds(array){
  array.forEach(item => {
    delete item["id"]
  })
}

export default class ContactModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      birthday: null,
      contact: props.contact,
      loading: false,
      name: null,
      showModal: props.show,
    }

    this.addressesRef = React.createRef()
    this.emailAddressesRef = React.createRef()
    this.phoneNumbersRef = React.createRef()
  }

  componentDidMount() {
    console.log("ContactModal.componentDidMount *********")
    // this.init()
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("ContactModal.componentDidUpdate")
    this.setMessage()
  }

  setContact(contact){
    // var contact = {
    //   'name': 'Jason',
    //   'birthday': '01-01',
    // }
    // contact['contactId'] = 'JASDH(*Y@HGASDt'
    // contact['addresses'] =  [{'address':'999'}]
    // contact['email_addresses'] =  [{'email_address':'x@x.com'}]
    // contact['phone_numbers'] = [{'number':'303-555-1234','type':'ET'}]
    if (contact !== undefined && contact !== null) {
      this.setState({'contact':contact})
      this.setState({'name':contact.name})
      this.setState({'birthday':contact.birthday})
    }

    this.setState({loading:false})
  }

  handleModalClose = async e => {
    console.log("ContactModal.handleModalClose")
    this.clearModal(false)
  }
  
  clearModal(reload) {
    console.log("ContactModal.clearModal")
    this.setState({birthday: null})
    this.setState({contact: undefined})
    this.setState({name: null})
    this.setState({loading: false})
    this.setState({showModal: false})

    if (reload && this.props.load !== undefined) {
      this.props.load()
    }
  }

  async update() {
    console.log("Contacts.update")
    const request = this.getRequest()    
    console.log("Contacts.update - request: "  + JSON.stringify(request))
    
    await fetch(CONTACTS_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
      .then(response => {
        console.log(response)
        return response.json()
      })
      .then(json => {
        console.log("Contacts.update: statusCode " + json.statusCode)
        console.log("Contacts.update: json: " + json);
      })
      .catch( err => {
        console.log("Contacts.update: Error: " + err)
      });
  }

  async handleSubmit(e){
    console.log("ContactModal.handleSubmit")
    this.setState({loading:true})
    
    this.update()

    this.clearModal(true)
  }

  handleReplaceChange = async e => {
    this.setState({replace:e.target.checked})
  }

  setContactId(contactId){
    this.setState({"contactId":contactId})
  }

  getRequest() {
    const token = sessionStorage.getItem('token');
    const { birthday, contact, name} = this.state

    let request = {
      "token" : token,
      "contact": {
        "name": name
      }
    }
    request["action"] = "create"
    if (contact !== undefined && contact.contactId !== undefined) {
      request["action"] = "update"
      request["contact"]["contactId"] = contact.contactId
    }
    if (birthday !== null && birthday !== "") {
      request["contact"]["birthday"] = birthday
    }
    try {
      const addrs = this.addressesRef.current.getAddresses()
      if (addrs.length > 0) {
        request["contact"]["addresses"] = addrs
      }
      const emails = this.emailAddressesRef.current.getEmailAddresses()
      if (emails.length > 0) {
        request["contact"]["email_addresses"] = emails
      }
      const nums = this.phoneNumbersRef.current.getPhoneNumbers()
      if (nums.length > 0) {
        request["contact"]["phone_numbers"] = nums
      }
    } catch(error) {
      console.log("ContactModal.getRequest() error " + error) 
    }
        
    return request
  }

  setMessage(){
    const request = JSON.stringify(this.getRequest(), null, 2)
    try {
      document.getElementById('contact-msg').innerHTML = request
    } catch(error) {
      console.log("ContactModal.setMessage() error " + error) 
    }
  }

  handleBirthdayChange(e) {
    this.setBirthday(e)
  }
  setBirthday(name) {
    this.setState({"birthday":name})
  }

  handleNameChange(e) {
    this.setName(e)
  }
  setName(name) {
    this.setState({"name":name})
  }
  
  showModal = async e => {
    console.log("ContactModal.showModal")
    this.setState({showModal:true})
  }

  render(){
    const {contact, loading, showModal} = this.state
    var title = "Edit"

    var {addresses, birthday, contactId, email_addresses, name, phone_numbers} = {undefined}

    if (contact !== undefined) {
      contactId = contact.contactId
      addresses = contact.addresses
      birthday = contact.birthday
      email_addresses = contact.email_addresses
      name = contact.name
      phone_numbers = contact.phone_numbers
    }

    if (contactId === undefined)
    {
      title = "New"
    }
    if (!showModal) {
      return <div></div>
    }
    return(
        <div className='contacts-upload' id='contacts-upload-modal'>
            <div className="contacts-upload-modal-content">
                <div>
                    <table>
                        <tbody>
                            <tr>
                                <td><h2>{title} Contact</h2></td>
                                <td align='right' width="3px">
                                    <div id='modal-close' onClick={this.handleModalClose}>X</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div><pre id="contact-msg" key={loading}></pre></div>
                    <input id="contactId" type="hidden" value={contactId}/>
                    <table>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>
                            <input autoComplete='new-password' id="name" type="text"
                              defaultValue={name}
                              onChange={e => this.handleNameChange(e.target.value)}/>
                          </td>
                        </tr>
                        <tr>
                          <td>Birthday</td>
                          <td>
                            <input autoComplete='new-password' id="birthday" type="text"
                              defaultValue={birthday}
                              onChange={e => this.handleBirthdayChange(e.target.value)}/>
                          </td>
                        </tr>
                        <tr id="borders">
                          <td>Email</td>
                          <td>
                            <EmailAddresses key={loading}
                              email_addresses={email_addresses}
                              editable={true}
                              ref={this.emailAddressesRef}
                              />
                          </td>
                        </tr>
                        <tr id="borders">
                          <td>Phone</td>
                          <td>
                            <PhoneNumbers key={loading}
                              phone_numbers={phone_numbers}
                              editable={true}
                              ref={this.phoneNumbersRef}
                              />
                          </td>
                        </tr>
                        <tr id="borders">
                          <td>Address</td>
                          <td>
                            <Addresses key={loading}
                              addresses={addresses}
                              editable={true}
                              ref={this.addressesRef}
                              />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    
                    <div id="upload-button" onClick={e => this.handleSubmit(e)}>Submit</div>
                </div>
            </div>
            { loading ? <Roller /> : null }
        </div>
    )
  }
}