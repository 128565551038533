import React from 'react';
import PropTypes from 'prop-types';
// import base64 from 'react-native-base64'
// import { EMAIL_ENDPOINT } from './api-backend';
import './MailMsgRow.css';

export default class MailMsgRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      folder: props.folder,
      selected_items:[]
    };

    this.handleRowClick = this.handleRowClick.bind(this)

    this.checkboxFunction = props.checkboxFunction()
    this.mailClick = props.mailClick
  }

  componentDidUpdate() {
    console.log("MailMsgRow.componentDidUpdate")
  }

  getFrom() {
    try {
      return this.state.item.from.replace(RegExp(" <[a-zA-Z-.]*@[a-zA-Z-.]*>"), "")
    }
    catch(error) {
      console.log("MailMsgRow.getFrom error " + error) 
      return this.state.item.from
    }
  }

  getSentTo() {
    const msg = this.state.item
    var to = null
    if (msg.to.map) {
      to = []
      to.push(msg.to.map(function(item, key) {
        if (key > 0) {
          return ", " + item
        }
        return item
      }));
    } else {
      to=msg.to
    }
    return to
  }

  handleRowClick(){
    console.log("MailMsgRow.handleRowClick")
    const msg = this.state.item
    console.log("MailMsgRow.handleRowClick for " + msg.recipient)
    this.mailClick(msg.messageId, msg.recipient)
    if (!msg.is_read) {
      console.log("MailMsgRow.handleRowClick marking as read")
      msg.is_read = true
      this.setState({item:msg})
    }
  }

  render() {
    var to_from = this.getFrom()
    if ( this.state.folder === "sent") {
      to_from = this.getSentTo()
    }

    const msg = this.state.item
    var  rowClass = "td-mail-unread"
    if (msg.is_read) {
      rowClass = "td-mail-read"
    }
    return(
      <tr className="tr-mail" key={msg.messageId}>
        <td align="left" width="20px">
          <input type="checkbox" onChange={e => this.checkboxFunction(e)} value={msg.messageId}/>
        </td>
        <td className={rowClass} align="left" onClick={this.handleRowClick}>
          {to_from}
        </td>
        <td className={rowClass} align="left" onClick={this.handleRowClick}>
            {msg.subject}
        </td>
        <td className={rowClass} align="left" onClick={this.handleRowClick}>
            {msg.date}
        </td>
      </tr>
    );
  }
}
  
MailMsgRow.propTypes = {
    item: PropTypes.object.isRequired,
    folder: PropTypes.string.isRequired,
    checkboxFunction: PropTypes.func.isRequired
};