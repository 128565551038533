import React from 'react';
import { Helmet } from 'react-helmet-async';

function Home() {
  console.log("version: " + process.env.REACT_APP_VERSION)
  console.log("backend: " + process.env.REACT_APP_BACKEND_HOST)

  return (
    <div id="body-root" align="left">
      <Helmet>
        <title>Home Page | Elliott Technologies</title>
        <meta name="keywords" content="arvada,denver,colorado,cloud,web,applications,network,design,integration" />
      </Helmet>
      <b>Welcome!</b>
      <hr />
      <p>We are based in Arvada, Colorado and serve the Denver metropolitan area. 
        We've been in business since November of 1999 helping companies with 
        cloud services, network design, system integration, repairs, upgrades, 
        custom applications including web based, and server/workstation support.
        The goal is to offer low cost - high quality service and support for your 
        home or business computer needs.
      </p>
      <p>If you mention that you found us on the web, Elliott Technologies will give you the first hour of your initial consultation for free.
      </p>
      <hr />
      <p align="center">For more information, please send e-mail to:<br />
        <a href="mailto:Info@Elliott-Technolgoies.com">Info@Elliott-Technologies.com</a>
      </p>
    </div>

  );
}

export default Home;
