// import React, { useState } from 'react';
import './Mailbox.css';
import { EMAIL_ENDPOINT } from './api-backend';
// import useAuth from './useAuth';
import React from 'react';
import MailItem from'./MailItem'
import Roller from '../roller/Roller';

export default class Mailbox extends React.Component{
  mailboxList = [];

  MyMailItem = <MailItem />

  constructor(props) {
    super(props);
    this.state = {
      mailboxes: null,
      mailbox: null,
      displayName: null
    };
  }

  componentDidMount() {
    console.log("Mailbox.componentDidMount")
    this.Mailbox();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Mailbox.componentDidUpdate: mailboxes " + this.state.mailboxes)
    if (this.state.mailboxes === null) {
      console.log("Mailbox.Mailbox: waiting for mailboxes")
    } else {
      // var last = null
      if (this.state.mailbox === null) {
        this.state.mailboxes.map((item, i) => {
          // this.getItemOption(item, i)
          if (item.primary) {
            console.log("Mailbox.Mailbox: setting mailbox")
            this.setState({mailbox: item.mailbox})
            this.setDisplayName(item.mailbox)
          }
          // last = item.mailbox
          return null
        });
      }
      console.log("Mailbox.componentDidUpdate: mailbox " + this.state.mailbox)
    }
  }

  async Mailbox() {
    console.log("MailBox.Mailbox")
    const token = sessionStorage.getItem('token');
    if (token === undefined || token === null) {
      window.location = "./"
      return 
    }
    const request = {
        "action": "get-mailboxes",
        "token" : token
    }
    await fetch(EMAIL_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(response => response.json())
        .then(json => {
          console.log("Mailbox.Mailbox: " + json.statusCode)
          if (json.statusCode === 401) {
            window.location = "./"
          }
          this.setState({mailboxes: json})
          console.log("json", json)
          return json
          })
        .catch( err => {
          console.log("Mailbox.Mailbox: Error " + err)
        });

  }

  setDisplayName(mailbox) {
    console.log("Mailbox.getDisplayName:" + mailbox)
    
    var displayName = undefined
    this.state.mailboxes.forEach(box => {
      if (box.mailbox === mailbox) {
        console.log("Mailbox.setDisplayName: found match")
        displayName = box.displayName
      }
    });
    console.log("Mailbox.setDisplayName: " + displayName)
    this.setState({displayName: displayName}) 
  }

  getItemOption(item, i) {
    let displayName = item.mailbox
    if (item.displayName !== null) {
      displayName = item.displayName + " <" + item.mailbox +">"
    }
    return <option value={item.mailbox} key={i}>{displayName}</option>
  }

  handleChange(e) {
    console.log("Mailbox.handleChange:" + e)
    this.setState({mailbox : e})
    this.setDisplayName(e)
  }

  
  render() {

    if (this.state.mailboxes === null ) {
      return <Roller />
    } else if (this.state.mailboxes[0] == null) {
      return <div className="App">We couldn't find any mailbox for you,
       please verify Elliott Technologies is hosting your domain's email...</div>
    } else {
      const boxes = this.state.mailboxes.map((item, i) => (
        this.getItemOption(item, i)
      ));
      //const [state, setState] = useState(Date.now());

      console.log("Mailbox.render: mailbox " + this.state.mailbox)
      return (
          <div className="App" key={this.state.mailbox} >
          <table>
            <thead>
              <tr>
              <td>
                <h2>Mailbox</h2>
              </td>
              <td>
                <select defaultValue={this.state.mailbox} onChange={e => this.handleChange(e.target.value)}>
                {boxes}
                </select>
              </td>
              </tr>
            </thead>
          </table>
          <MailItem mailbox={this.state.mailbox} displayName={this.state.displayName}/>
          </div>
      )
    } 
  }

  setMailboxList = ref => {
    console.log("Mailbox.setMailboxList: " + ref)
    this.mailboxList = ref
  }
}
