import React from 'react';
import './MoviePlayer.css'
import MediaButton from './MediaButton';

export default class MoviePlayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      source: "null",
      time: 0,
      // loading: true
    }
    this.refresh = props.refresh
  }

  componentDidMount() {
    console.log("MoviePlayer - componentDidMount")
    // this.playVideo();
  };

  componentWillUnmount() {
    // this.pauseVideo();
  };

  setSource(source) {
    console.log("MoviePlayer.setSource - source " + source)
    this.setState({"source": source})
    const video = document.getElementById("videoRef")
    if (video === null) {
      console.log("MoviePlayer.playVideo - videoRef is null")
      return
    }
    video.src = source
  }

  setName(name) {
    console.log("MoviePlayer.setName - name " + name)
    this.setState({"name": name})
  }
  setTime(time) {
    console.log("MoviePlayer.setTime - time " + time)
    this.setState({"time": time})
  }

  closePlayer(){
    this.pauseVideo()
    document.getElementById("video-player").style.display = "none";
    const video = document.getElementById("videoRef")
    video.src = ""
    video.load()
    this.refresh()
  }

  playVideo() {
    console.log("MoviePlayer.playVideo")
    const video = document.getElementById("videoRef")
    if (video === null) {
      console.log("MoviePlayer.playVideo - videoRef is null")
      return
    }

    const { name } = this.state
    const time = localStorage.getItem(name + "currentTime")
    console.log("MoviePlayer.playVideo - currentTime " + name + time) 
    video.currentTime = time
    document.getElementById("video-player").style.display = "block";
    video.play()
      .then(function() {
        console.log("MoviePlayer.playVideo - playback started!")
      })
      .catch(function(error) {
        console.log("MoviePlayer.playVideo - playback failed! " + error)
      });
    document.getElementById("videoRef").style.width = '80%'
  };

  pauseVideo() {
    console.log("MoviePlayer.pauseVideo")
    const { name } = this.state
    const video = document.getElementById("videoRef")
    if (video === null)
      return
    video.pause();
    const time = video.currentTime
    console.log("MoviePlayer.pauseVideo - currentTime " + name + time) 
    localStorage.setItem(name + "currentTime", time)
    console.log("MoviePlayer.pauseVideo - playback paused!")
  };


  render() {
    console.log('MoviePlayer.render')
    
    return <div id="video-player" className='video-player'>
          <MediaButton altSource="X" customClickEvent={this.closePlayer.bind(this)}>Close</MediaButton>
    	  <video id="videoRef" controls controlsList="nodownload" preload="auto" onPause={() => this.pauseVideo()} onPlay={() => this.playVideo()} >
            <source 
              src=""
               /> 
          </video>
      </div>
  };
}
