import React from 'react';
import './EmailAddress.css'

export default class Phone extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: props.editable,
            number: props.number,
            id: props.id,
            type: props.type,
        }
        // this.callback = props.callback
        // this.updater = props.updater
    }

    componentDidMount() {
        const { editable } = this.state
        console.log("Phone.componentDidMount")
        if (editable === undefined) {
            this.setState({ editable: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("Phone.componentDidUpdate")        
    }

    change(id, num, type) {
        console.log("change ", id)
        // this.props.callback(id, email, type)
        this.setState({number: num})
        this.setState({type: type})
        // this.props.updater()
    }

    delete(id) {
        console.log("delete ", id)
        // this.props.callback(id, null, null, true)
    }

    get(){
        const { number, type } = this.state
        return {
            "number":number,
            "type":type
        }
    }
    render() {
        const { editable, number, id, type } = this.state
        // console.log("Phone.render number " + number)
        if (editable){
            
            return <span className='email-address'>
                <input autoComplete='new-password' 
                    id="email"
                    type="text"
                    defaultValue={number}
                    onChange={e => this.change(id, e.target.value, type)} />
                &nbsp;
                <input autoComplete='new-password' 
                    id="type"
                    type="text"
                    defaultValue={type}
                    size="2"
                    list="types"
                    onChange={e => this.change(id, number, e.target.value)} />
                <datalist id="types">
                    <option value="Home">Home</option>
                    <option value="Other">Other</option>
                    <option value="Work">Work</option>
                </datalist>
                </span>
        }
        return <div>
            <span>{number}&nbsp;({type})</span>
        </div>
    }
}  