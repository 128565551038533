import LoginControl from './components/LoginControl';
import logo from './et.png';

function Header() {
    return (
        <div className="Header">
        <header className="App-header">
            <table width="100%" height="100px">
            <tbody>
            <tr>
                <td align="left">
                <img src={logo} className="App-logo" alt="logo" />
                </td>
                <td align="right">
                <LoginControl />
                </td>
            </tr>
            </tbody>
            </table>    
        </header>
        </div>
    );
}
  
export default Header;
