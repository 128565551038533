import React from 'react';
import './EmailAddress.css';
import { appendIds } from './ContactModal';
import EmailAddress from './EmailAddress';

export default class EmailAddresses extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: props.editable,
            email_addresses: props.email_addresses,
            count: 0,
            elem_list: [],
            myRefs: [],
        }
    }

    componentDidMount() {
        console.log("EmailAddresses.componentDidMount *********")
        const { email_addresses, editable } = this.state
        if (editable === undefined) {
            this.setState({ editable: false })
        }
        if (email_addresses === undefined) {
            this.setState({email_addresses:[]})
        }
        appendIds(email_addresses)
        this.generateList(email_addresses)
        this.setState({'email_addresses':[]})
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("EmailAddresses.componentDidUpdate")        
    }

    addNew(){
        const {count} = this.state
        console.log("EmailAddresses.addNew ", count)
        const array = this.getEmailAddresses()
        array.push({})
        appendIds(array)
        this.generateList(array)
    }

    delete(id) {
        console.log("EmailAddresses.delete ", id)
        const tmp = this.getEmailAddresses()
        appendIds(tmp)
        const array = tmp.filter(function(item) {
            return item.id !== id}
        )        
        appendIds(array)
        this.generateList(array)
    }

    generateList(array){
        this.setState({address_list: []})
        const {editable} = this.state
        console.log("EmailAddresses.generateList " + JSON.stringify(array))
        let elem_list = []
        let ref_list = []
        if (array !== undefined && array !== null) {
            array.forEach(item => {
                const aRef = React.createRef()
                const key = "email" + item.id + item.email_address + item.type
                const elem = <div id='no-padding' key={key} className="email-address">
                    <EmailAddress
                        id={item.id}
                        email_address={item.email_address}
                        editable={editable}
                        type={item.type}
                        ref={aRef}
                    />
                    &nbsp;
                    <span id='delete-item' title='delete'
                        onClick={e => this.delete(item.id)}>
                        X
                    </span>
                </div>
                ref_list.push(aRef)
                elem_list.push(elem)
            })
        }
        this.setState({'elem_list': elem_list})
        this.setState({'count': elem_list.length})
        this.setState({'myRefs': ref_list})
    }

    getEmailAddresses(){
        const { myRefs } = this.state
        let array = []
        if (myRefs !== undefined) {
            myRefs.forEach(ref => {
                array.push(
                    ref.current.get()
                )
            })
        }
        return array
    }

    render() {
        console.log("EmailAddresses.render")
        const {elem_list} = this.state
        return <div id="no-padding">
                {elem_list}
                <div id="no-padding">
                    <button title='add' onClick={e => this.addNew(e)}>+</button>
                </div>
            </div>
    }
}  