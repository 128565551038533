import './App.css';
import Login from './components/Login';
import VerifyMFA from './components/VerifyMFA';
import useAuth from './components/useAuth';
import Roller from './roller/Roller';

function LoginFlow() {
  const {token, auth, setToken, setAuth} = useAuth();

  if (!token) {
    return (
      <div>
        <Login setToken={setToken} />
      </div>
    )
  } else if (!auth) {
    return <VerifyMFA setAuth={setAuth} />
  }

  return (
    <Roller />
  );
}

export default LoginFlow;
