import React from 'react';
// import { Link } from "react-router-dom";

export default class MediaAuth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // currentTime: localStorage.getItem("currentTime")
    }
  }

  componentDidMount = () => {
    // this.playVideo();
  };

  componentWillUnmount = () => {
    // this.pauseVideo();
  };

  render() {
    // const { email, passwordSet, token, verification } = this.state;

    return  <div>
          <div>
          <h2>Media Authorization</h2>
          </div>
      </div>
  };
}
