import React from 'react';
import PropTypes from 'prop-types';
import './MailItem.css';

export default class MailFolder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_folder: props.current_folder,
      folder: props.folder
    };
    this.handleClick = props.folderClick()
  }

  componentDidMount() {
    console.log("MailFolder.componentDidMount: folder " + this.state.folder)
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("MailFolder.componentDidUpdate: folder " + this.state.folder)
  }

  getClassName(){
    if (this.state.current_folder === this.state.folder) {
      return "mail-current-folder"
    }
    else {
      return "mail-folder"
    }
  }

  getFolderDiv() {
    return <div 
        key={this.state.folder}
        className={this.getClassName()}
        onClick={() => this.handleClick(this.state.folder)}
        style={{textTransform: 'capitalize'}}>{this.state.folder}</div>;
  }
  
  render () {
    return(this.getFolderDiv() )
  };
}

MailFolder.propTypes = {
  current_folder: PropTypes.string.isRequired,
  folder: PropTypes.string.isRequired
};