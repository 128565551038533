import React from 'react';
// import './SignUp.css';
// import PropTypes from 'prop-types';
import SignUpEmail from './sign-up/SignUpEmail';
import SignUpPassword from './sign-up/SignUpPassword';
import SignUpVerify from './sign-up/SignUpVerify';


export default class ForgotPassword extends React.Component {

  constructor() {
    super();
    this.state = {
      email: null,
      passwordSet: null,
      token: null,
      verification: null
    }

    this.setEmail = this.setEmail.bind(this)
    this.setVerification = this.setVerification.bind(this)
    this.setPassword = this.setPassword.bind(this)
  }

  componentDidMount() {
    console.log("SignUp.componentDidMount")
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("SignUp.componentDidUpdate")
  }
  
  setEmail(value) {
    console.log("SignUp.setEmail " + value)
    this.setState({email: value})
  }
  setPassword(value) {
    console.log("SignUp.setPassword")
    this.setState({passwordSet: "set"})
  }
  setVerification(value) {
    console.log("SignUp.setVerification " + value)
    this.setState({verification: value})
    this.setState({token: value})
  }

  render () {
    const { email, passwordSet, token, verification } = this.state;

    if ( email === null ) {
      return <SignUpEmail setEmail={this.setEmail} reset={true}/>
    } 
    else if (verification === null ) {
      return <SignUpVerify email={email} setVerification={this.setVerification} />
    }
    else if (passwordSet === null) {
      return <SignUpPassword email={email} token={token} setPassword={this.setPassword} />
    }
    return  <div>
          You're successfully reset your password, you may now <a href="./login">login</a>.
        </div>
  };
}
