import React from 'react';
import './MailItem.css';
import MailFolders from './MailFolders'

export default class MailItem extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      mailbox: props.mailbox,
      displayName: props.displayName
    };

    console.log("MailItem.constructor: mailbox " + this.state.mailbox)
  }

  componentDidMount() {
    console.log("MailItem.componentDidMount: mailbox " + this.state.mailbox)
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("MailItem.componentDidUpdate: mailbox " + this.state.mailbox)
  }

  render() {
    if (this.state.mailbox === null ) {
      return <div className="no-messages">Retrieving your mailbox, please wait...</div>
    } else {
      return (
        <div className='mailbox'>
          <div className="mail-msg-modal" id="compose-modal-root">
            <div>To</div>
            <div>Message<textarea></textarea></div>
          </div>
          <MailFolders mailbox={this.state.mailbox} 
              key={this.state.mailbox} 
              displayName={this.state.displayName}
              />
        </div>
      )
    }
  }
}