import React from 'react';
import './MoviePlayer.css';
import { DEVICE_ID_HEADEER, VIDEOS_ENDPOINT } from '../components/api-backend';

export default class Movie extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: props.name,
      source: props.source,
      time: props.time,
      poster_url: props.poster_url,
      overview: props.overview,
      release_date: props.release_date,
      id: props.id,
      // loading: true
    }
    this.playerRef = props.playerRef
  }

  componentDidMount() {
    const {poster_url, time} = this.state
    console.log("Movie.componentDidMount " + poster_url)
    if (time === undefined) {
      this.setState({time:0})
    }
  };

  componentWillUnmount() {
    // this.pauseVideo();
  };

  saveDetail(){
    const { id, poster_url, release_date, source } = this.state;
    const video = {
      "key": source,
      "tmdb_id": id,
      "release_date": release_date,
      "poster_url": poster_url
    }
    console.log("video: " + video)
    const deviceId = localStorage.getItem(DEVICE_ID_HEADEER)
    fetch(VIDEOS_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json',
          'X-Elliott-Technologies-Device-Id': deviceId
        },
        body: JSON.stringify(video)
      }).then(response => {
          if (response.status === 401) {
            sessionStorage.clear()
            window.location = "./media-auth"
          }
          return response.json()
        })
        .then(json => {
          // console.log("Contacts.load: " + json.statusCode)
          // sessionStorage.setItem("contacts", JSON.stringify(json))
          this.setState({movies: json.videos})
          console.log("Movies.load json", json)
          this.setState({loading:false})
          // this.playerRef.current.setSignedUrl(source)
    	  // return json.url
          this.playerRef.current.setSource(json.url)
          // this.playerRef.current.setTime(time)
          this.playerRef.current.playVideo()
          })
        .catch( err => {
          console.log("Movies.load: Error " + err)
          this.setState({loading:false})
        }); 
    

  }

  render() {
    const { name, overview, poster_url, release_date, id } = this.state;
    var img = null
    if (poster_url !== undefined) {
    	console.log("Movie found poster_url")
    	img = <img
    	  src={'https://image.tmdb.org/t/p/w92' + poster_url} 
    	  alt="movie poster"
    	/>
    }
    else {
     img = name.replace('.m4v','')
    }
    return  <span onClick={() => this.saveDetail()} >
        {img}<br />
        {id}<br />
        {release_date}<br />
        {poster_url}<br />
        {overview}
      </span>
  };
}
