import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import './Login.css';
import { AUTH_ENDPOINT, MFA_ENDPOINT } from './api-backend';
import Roller from '../roller/Roller';
import Cookies from 'universal-cookie';
import randomBytes from 'randombytes'
import useAuth from './useAuth';

async function loginUser(credentials, deviceId) {
  return fetch(AUTH_ENDPOINT, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json',
      'X-Elliott-Technologies-Device-Id': deviceId
    },
    body: JSON.stringify(credentials)
  })
    .then(data => {
      let json = data.json()
      // console.log("json " + json)
      return json
    })
    // .then(response => {
    //   console.log("response " + response)
    //   // response.json()
    // })
    .catch( err => {
      console.log("Login.loginUser: Error " + err)
    })
    
}

async function sendMFA(request) {
  return fetch(MFA_ENDPOINT, {
    method: 'POST', 
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(request)
  })
    .then(data => data.json())
}

export default function Login({setToken}) {
  
  const [loading, setLoading] = useState(false);
  // const [addr, setAddr] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [message, setMessage] = useState();
  const { setAuth } = useAuth();
          
  const onPasswordChange = async e => {
    // console.log("Login.onPasswordChange " + e.key)
    if (e.key === "Enter") {
      handleSubmit(e)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault();
    console.log("Login.handleSubmit")
    setLoading(true);
    if (email === undefined || email === "" || password === undefined || password === "" ) {
      setMessage("Email & Password are required")
      setLoading(false);
      return false
    }
    setMessage("Logging you in, please wait...")
    
    const cookies = new Cookies();
    if (cookies.get('rememberme') === undefined) {
      const lookup = randomBytes(9)
      const rand = randomBytes(12)
      const rememberme = lookup.toString('hex') +'.'+rand.toString('hex')
      console.log("rememberme " + rememberme)
      let opts = null
      // opts =  {'secure':true,'sameSite':'strict'}
      opts = { 'maxAge' : 7776000 } // 90 days in seconds
      
      cookies.set('rememberme', rememberme, opts)
    }
    console.log("cookie " + cookies.get('rememberme'));

    const loginResp = await loginUser({
      "method": "authenticate",
      email,
      password
    }, cookies.get('rememberme'));
    // console.log("loginResp " + loginResp)
    
    setLoading(false);

    if (loginResp === undefined){
      setMessage("Invalid user and/or pasowrd")
    } else {
      // const resp = loginResp.body
      // console.log("verified: " + resp.verified)
      if (loginResp.verified) {
        if (loginResp.skip_mfa) {
          setAuth(true)
          window.location = "./user"
        } else {
          sendMFA({"action": "create", "token": loginResp.token});
        }
        const rememberme = cookies.get('rememberme')
        cookies.set('rememberme', rememberme, { 'maxAge' : 7776000 })
        setToken(loginResp.token);
      } else {
        setMessage("Invalid user and/or pasowrd")
      }
    }
  }

  // setAddr($_SERVER['REMOTE_ADDR'])
  // setLoading("none")

  return(
    <div className="App" >
      <Helmet>
        <title>Login | Elliott Technologies</title>
        <meta name="keywords" content="arvada,denver,colorado,cloud,web,applications,network,design,integration" />
      </Helmet>
      <h2>Let's do this - Login</h2>
      {/* {addr} */}
      <div className="login-invalid"><b>{message}</b></div>
        <form onSubmit={handleSubmit}>
        <table align='center'>
          <tbody>
            <tr>
              <td align="right">
                Email
              </td>
              <td>
                <input autoComplete='username' type="text" onChange={e => setEmail(e.target.value)}/>
              </td>
            </tr>
            <tr>
              <td align="right">
                Password
              </td>
              <td>
                <input autoComplete='current-password' type="password" onChange={e => setPassword(e.target.value)} onKeyDown={onPasswordChange}/>
              </td>
            </tr>
          </tbody>
        </table>
        </form>
        <div style={{padding:5}}>
          <a href="./forgot-password">Forgot Password</a>
          <span style={{padding:5}}></span>
          <button className="login-btn" onClick={handleSubmit} type="submit">Submit</button> 
        </div>
        <div style={{padding:5}}>
        Not registered, why not? It's easy!
        <br/><a href="./sign-up">Sign Up</a>
        
        </div>
        {loading ? <Roller /> : null}
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};