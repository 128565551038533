let backendHost;

const hostname = window && window.location && window.location.hostname;
// console.log("hostname " + hostname)
if(hostname === 'www.elliott-technologies.com') {
  backendHost = 'https://api.elliott-technologies.com';
} else if(hostname === 'dev-api.elliott-technologies.com') {
  backendHost = 'https://dev-api.elliott-technologies.com';
} else {
  backendHost = process.env.REACT_APP_BACKEND_HOST || 'https://dev-api.elliott-technologies.com';
}

export const API_ROOT = `${backendHost}`;
export const AUTH_ENDPOINT = `${backendHost}/security/auth`;
export const CONTACTS_ENDPOINT = `${backendHost}/contacts/info`;
export const EMAIL_ENDPOINT = `${backendHost}/email/info`;
export const MFA_ENDPOINT = `${backendHost}/mfa/info`;

export const MUSIC_ENDPOINT = `${backendHost}/media/music`;
export const VIDEOS_ENDPOINT = `${backendHost}/media/videos`;
export const MEDIA_URL_ENDPOINT = `${backendHost}/media/url`;

export const DEVICE_ID_HEADEER = 'X-Elliott-Technologies-Device-Id'
