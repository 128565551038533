import React from 'react';
import MovieDetail from './MovieDetail';
import Roller from '../roller/Roller';
import { DEVICE_ID_HEADEER, VIDEOS_ENDPOINT } from '../components/api-backend';

export default class Movies extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTime: localStorage.getItem("currentTime"),
      loading: false,
      movies: []
    }
    this.detailRef = React.createRef()
  }

  componentDidMount = () => {
      this.load()
  };

  componentWillUnmount = () => {

  };

  async load() {
    console.log("Movies.load")
    this.setState({loading:true})
    const deviceId = localStorage.getItem(DEVICE_ID_HEADEER)
    var headers = {
      'Content-Type': 'application/json'
    }
    if (deviceId !== null) {
      headers = {
        'Content-Type': 'application/json',
        'X-Elliott-Technologies-Device-Id': deviceId
      }
    }
    console.log("Movies.load - headers " + JSON.stringify(headers))
    await fetch(VIDEOS_ENDPOINT, {
        headers: headers
      }).then(response => {
          console.log("Movies.load - response " + response)
          if (response.status === 401) {
            sessionStorage.clear()
            window.location = "./media-auth"
          }
          return response.json()
        })
        .then(json => {
          // console.log("Contacts.load: " + json.statusCode)
          //sessionStorage.setItem("contacts", JSON.stringify(json))
          let vids = json.videos
          if (vids === undefined) {
            vids = [{'key':'na','name':'No video content found'}]
          }
          this.setState({movies: vids.sort(this.nameSorter)})
          console.log("Movies.load json", json)
          this.setState({loading:false})
          return json
          })
        .catch( err => {
          console.log("Movies.load: Error " + err)
          this.setState({loading:false})
        });
  }
  
  showDetail(movie) {
    this.detailRef.current.setName(movie.name.replace('.m4v',''))
    this.detailRef.current.setPosterUrl(movie.poster_url)
    this.detailRef.current.setSource(movie.key)
    this.detailRef.current.setTmdbId(movie.tmdb_id)
    const detail = document.getElementById("movieDetailRef")
    detail.style.display = "inline-block";
  }
  
  nameSorter( a, b ) {
    if ( a.name < b.name ){
      return -1;
    }
    if ( a.name > b.name ){
      return 1;
    }
    return 0;
  }
  
  reorder(){
    console.log("Movies.reorder")
    const { movies } = this.state;
    this.setState({movies:movies.sort(this.releaseSorter)})
  }
  
  releaseSorter( a, b ) {
    if ( a.release_date + a.name > b.release_date + b.name ){
      return -1;
    }
    if ( a.release_date + a.name < b.release_date + a.name ){
      return 1;
    }
    return 0;
  }

  render() {
    console.log("Movies.render")
    const { loading, movies } = this.state;
    console.log("Movies.render loading: " + loading)
    
    if (loading || movies === null || movies === "null" || movies === undefined) {
      return (
        <Roller key={loading} display={loading}/>
      )
    }


    console.log("Movies.render movies: " + movies)
    try { 
      let movieList = []
      movies.forEach(movie => {
        console.log("Movies.render movie " + movie)
        const { name, poster_url } = movie;
        
        var elem = null
        if (poster_url !== undefined) {
    	  console.log("Movie found poster_url")
    	  elem = <img key={movie.key}
    	    src={'https://image.tmdb.org/t/p/w154' + poster_url} 
            alt="movie poster"
          />
        }
        else {
         elem = name.replace('.m4v','')
        }
        movieList.push(
          <span key={movie.key} onClick={() => this.showDetail(movie)} >
            {elem}
          </span>
        )
      });

      
      return  (
        <div id="movieFrameRef" className="movie-frame">
        <div onClick={() => this.reorder()}>Sort by releade date</div>
        {movieList}
        <MovieDetail ref={this.detailRef}/>
        <div id="movieDetailBgRef" className="movie-detail-bg"></div>
        </div>
      )
    } catch(error) {
      console.log("Movies.render error " + error) 
    }
  };
}
