import React from 'react';
import './App.css';
import ContactsLoader from './contacts/ContactsLoader';

function Home() {
  
  const token = sessionStorage.getItem('token');
  if (token === undefined || token === null || token === "") {
    sessionStorage.clear()
    window.location = "./"
    return 
  } else {

  return (
     
      <div id="body-root" align="left">
      <b>Welcome, (user)!</b>
        <hr />
        <p>(catch phrase)</p>
        <ContactsLoader />
      </div>
   
  );
  }
}

export default Home;
