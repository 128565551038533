import React from 'react';
import './SignUp.css';
import { AUTH_ENDPOINT } from '../components/api-backend'
import PasswordPolicy from '../password/PasswordPolicy'
import Roller from '../roller/Roller';
import StrenghtMeter from '../password/StrenghtMeter'

async function storePassword(body) {
  console.log("SignUpPassword storePassword")
  const sendMailResp = fetch(AUTH_ENDPOINT, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(data => data.json())
      .catch(error =>(
        console.log(error)
      ))
  console.log(sendMailResp)
  return sendMailResp
}

export default class  SignUpPassword extends React.Component {
  // const {email, password, verification, setEmail} = useSignUp();
  constructor(props) {
    super(props);
    this.state = {
      email: props.email,
      token: props.token,
      password: '',
      passwordConfirm: null,
      loading: false
    }

    this.policyRef = React.createRef()
    this.setPassword = props.setPassword
  }

  componentDidMount() {
    console.log("SignUpPassword.componentDidMount")
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("SignUpPassword.componentDidUpdate")
  }
  
  // checkEmail() {
  //   if (this.validEmail(email)) {
  //     return <div className="check-passed">&#10003;</div>
  //   }
  //   return <div className="check-failed">X</div>
  // }
  checkPasswords() {
    const { password, passwordConfirm } = this.state;
    if (passwordConfirm !== null && password === passwordConfirm) {
      return true
    }
    return false
  }

  divCheckPasswords() {
    if (this.checkPasswords()) {
      return <div className="check-passed">&#10003;</div>
    }
    return <div className="check-failed">X</div>
  }

  async handleSignUpClick() {
    console.log("SignUpPassword.handleSignUpClick")
    this.setState({'loading':true})

    const { email, password, token } = this.state;

    const payload = {
      "method": "update-token",
      "email": email,
      "token": token,
      "password":password
    }
    console.log("SignUpPassword.handleSendClick storing - " + JSON.stringify(payload))
    const storeResp = await storePassword(payload);
    console.log("SignUpPassword.handleSendClick storeResp " + storeResp)
    this.setPassword()
    this.setState({'loading':false})
  }

  render() {
    const { loading, password, passwordConfirm } = this.state;
    // const testedResult = zxcvbn(password);
    
    let btn = document.getElementById("sign-up-btn")
    let buttonDisabled = true
    if ( btn !== null) {
      if (this.policyRef.current !== null
        && this.policyRef.current.isCompliant(password)
        && this.checkPasswords()) {
          buttonDisabled = false
      }
    }

    return  <div>
          <h2>Enter Password</h2>
          <p/>
          <table align='center'>
          <tbody>
            <tr>
              <td align="right" valign='top'>
                Password
              </td>
              <td >
                <input autoComplete="off" type="password" onChange={e => this.setState({ password: e.target.value })}/>
                <br/>
                <StrenghtMeter 
                  password={password}
                  />
              </td>
            </tr>
            <tr>
              <td>
                Confirmation
              </td>
              <td>
                <input autoComplete="off" type="password" onChange={e => this.setState({ passwordConfirm: e.target.value })}/>
              </td>
            </tr>

          </tbody>
        </table>
        <div style={{padding: "5px"}}>
          <button id="sign-up-btn" 
            className="login-btn" 
            disabled={buttonDisabled} 
            onClick={() => this.handleSignUpClick()}>Sign Up</button>        </div>
        <PasswordPolicy
          password={password}
          passwordConfirm={passwordConfirm}
          ref={this.policyRef}
          />
        {loading ? <Roller /> : null}
      </div>
  };
}
