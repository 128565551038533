import React from 'react';
import base64 from 'react-native-base64'
import { EMAIL_ENDPOINT } from './api-backend';
import './MailMessage.css';
import ReactDOMServer from 'react-dom/server';
// import { Link } from "react-router-dom"
// import ReactHtmlParser from 'react-html-parser'; 
import ResetTimeout from '../app/resetTimeout'

export default class MailMessage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      message: null,
      subject: "undefined",
      showSource: false,
      attachments: null,
      body: null,
      date: null,
      from: null,
      cc: null,
      to: null,
      replyTo: null
    };

    this.handleMailClick = this.handleMailClick.bind(this)
    this.handleMailCloseClick = this.handleMailCloseClick.bind(this)

    this.composeTo = props.composeTo
    this.composeCc = props.composeCc
    this.composeSubject = props.composeSubject
    this.composeSetSelectedFile = props.composeSetSelectedFile
  }

  componentDidUpdate() {
    console.log("MailMessage.componentDidUpdate")
    if (this.state.message !== null) {
      console.log("MailMessage.componentDidUpdate: show message")
      let modalRoot = document.getElementById('mail-modal');
      modalRoot.style.display = "block";
    } else {
      console.log("MailMessage.componentDidUpdate: hide message")
      let modalRoot = document.getElementById('mail-modal');
      modalRoot.style.display = "none";
    }
  }

  handleMailClick = async (messageId, mailbox)  => {
    console.log("MailMessage.handleMailClick: " + messageId + " " + mailbox)
    this.getMessage(messageId, mailbox)
    let modalRoot = document.getElementById('mail-modal');
    modalRoot.style.display = "block";
  }
  handleMailCloseClick = async e => {
    console.log("MailMessage.handleMsgCloseClick")
    this.setState({attachments:null})
    this.setState({cc:null})
    this.setState({date:null})
    this.setState({from:null})
    this.setState({message:null})
    this.setState({showSource:false})
    this.setState({subject:null})
  }

  handleHideSource = async e => {
    console.log("MailMessage.handleShowSource")
    this.setState({showSource:false})
    let contents = document.getElementById('msg-modal-contents');
    contents.style.display = "block";
    let source = document.getElementById('msg-modal-source');
    source.style.display = "none";
  }

  handleReplyAll = async e => {
    console.log("MailMessage.handleReplyAll")
    this.handleReply(e)
    if (this.state.cc != null) {
      document.getElementById('compose-cc').value = this.state.cc
      this.composeCc(this.state.cc)
    }
  }

  switchToComposeModal() {
    let modalRoot = document.getElementById('mail-modal');
    modalRoot.style.display = "none";

    let contents = document.getElementById('compose-modal');
    contents.style.display = "block";
  }

  setReFwdSubject(replying) {
    let prefix = "Re: "
    if (!replying) {
      prefix = "Fwd: "
    }
    let subject = null
    if (this.state.subject !== null) {
      if (this.state.subject.indexOf(prefix) !== 0) {
        subject = prefix + this.state.subject
      } else {
        subject = this.state.subject
      }
    }
    document.getElementById('compose-subject').value = subject;
    this.composeSubject(subject)
  }

  handleReply = async e => {
    console.log("MailMessage.handleReply")
    
    this.switchToComposeModal()
    this.setReFwdSubject(true)
    
    var to = null
    if (this.state.replyTo !== null) {
      to = this.state.replyTo
    } else {
      to = this.state.from
    }
    document.getElementById('compose-to').value = to
    this.composeTo(to)

    const msg = <div style={{padding:"5px"}}>
      <div><br/><br/>On {this.state.date}, {this.state.from} wrote:</div>
      <div style={{borderLeft:"1px solid #888",paddingLeft:"1ex"}} id="reply-msg"></div>
      </div>
    this.setComposeMessage(msg, "fwd-msg")
  }

  getForwardedDiv(){
    let cc = null
    if (this.state.cc !== null) {
      cc = "Cc: " + this.state.cc
    }
    return <div>
      <br/>
      <br/>
      -------- Forwarded Message --------<br/>
      From: {this.state.from}<br/>
      Date: {this.state.date}<br/>
      Subject: {this.state.subject}<br/>
      To: {this.state.to}<br/>
      {cc}
    </div>
  }

  setComposeMessage(msg, id){
    let body = document.getElementById('compose-body');
    body.innerHTML = ReactDOMServer.renderToString(msg)
    let prev = document.getElementById(id);
    prev.innerHTML = this.state.body
  }

  handleForward = async e => {
    console.log("MailMessage.handleForward")
    
    this.switchToComposeModal()
    this.setReFwdSubject(false)

    if (this.state.attachments != null) {
      this.state.attachments.forEach((item) => {
        const json = JSON.parse(item)
        const data = json.data.replaceAll("\r\n","")
        console.log("MailMessage.render attachment " + json.name )
        const file = new File([data], json.name, {type:'application/octet-stream'})
        this.composeSetSelectedFile(file)
      })
    }

    const msg = <div style={{padding:"5px"}}>
      {this.getForwardedDiv()}
      <p/>
      <div id="fwd-msg"></div>
      </div>
    this.setComposeMessage(msg, "fwd-msg")
  }

  handleShowSource = async e => {
    console.log("MailMessage.handleShowSource")
    this.setState({showSource:true})
    let contents = document.getElementById('msg-modal-contents');
    contents.style.display = "none";
    let source = document.getElementById('msg-modal-source');
    source.style.display = "block";
  }
  
  getHtmlMessage(msg, source, subject){
    console.log("MailMessage.getHtmlMessage")
    var split_upon = "\r\n"
    if (msg.indexOf(split_upon) === -1) {
      split_upon = "\n"
    }
    let contents = ""
    if (msg.indexOf("<div") !== -1) {
      contents = msg
    } else {
      console.log("MailMessage.getHtmlMessage processing text/plain")
      var data = msg.split(split_upon)
      data.forEach((item) => {
        if (item !== "") {
          try{
            item = base64.decode(item)
          }
          catch(error) {
            console.log("MailMessage.getHtmlMessage base64 decoding error ignored: " + error) 
          }
          contents += "<p>" + item + "</p>"
        }
      })
    }

    var decoded_src = null
    if (source !== undefined) {
      decoded_src = base64.decode(source).replaceAll(split_upon,"<br />")
    }
  
    return (
      <div>
        <div id="msg-modal-contents" dangerouslySetInnerHTML={{__html: contents}}>
        </div>
        <div id="msg-modal-source" className="msg-modal-source" dangerouslySetInnerHTML={{__html: decoded_src}}></div>
      </div>
    )
  }

  async getMessage(messageId, mailbox) {
    console.log("MailMessage.getMessage: "  + messageId)
    // this.setState({message : this.getHtmlMessage("\r\nRetrieving message...")})
    // this.setState({subject : "Retrieving ..."})

    const token = sessionStorage.getItem('token');
    const request = {
      "action": "read",
      "token" : token,
      "messageId": messageId,
      "mailbox": mailbox
    }
    
    await fetch(EMAIL_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(data => {
          const json = data.json()
          // console.log("MailFolders.getMessage: json: " + json);
          return json
          })
          .then(json => {
            console.log("MailMessage.getMessage: json ", json)
            // console.log("MailMessage.getMessage: json.body.headers " + json.body.header)
            // this.setSubject(json.body.header)
            this.setState({subject: json.body.subject})
            this.setState({body: json.body.message})
            this.setState({attachments: json.body.attachments})
            this.setState({from: json.body.from})
            this.setState({to: json.body.to})
            this.setState({cc: json.body.cc})
            this.setState({date: json.body.date})
            this.setState({replyTo: json.body.replyTo})
            // console.log("MailMessage.getMessage: suubject", this.state.subject)
            this.setState({
              message: this.getHtmlMessage(
                json.body.message,
                json.body.source
              )
            })
            // console.log("MailMessage.getMessage: message", json.body.message)
            return json.body
          })
        .catch( err => {
          console.log("MailMessage.getMessage: Error: " + err)
          this.setState({message : this.getHtmlMessage("\r\nError: unable to retrieve message")})
        });
  }

  render() {
    let sourceAction = <div className="btn" onClick={this.handleHideSource}>[ Hide Source ]</div>
    if (!this.state.showSource) {
      sourceAction = <div className="btn" onClick={this.handleShowSource}>[ Show Source ]</div>
    }

    const header = <table>
      <tbody>
        <tr>
          <td>
            {sourceAction}
          </td>
          <td>
            <div className="btn" onClick={this.handleReply}>reply</div>
          </td>
          <td>
            <div className="btn" onClick={this.handleReplyAll}>reply all</div>
          </td>
          <td>
            <div className="btn" onClick={this.handleForward}>forward</div>
          </td>
        </tr>
      </tbody>
    </table>
    let attachments = []
    if (this.state.attachments != null) {
      this.state.attachments.forEach((item) => {
        const json = JSON.parse(item)
        const data = json.data.replaceAll("\r\n","")
        console.log("MailMessage.render attachment " + json.name )
        const file = new File([data], json.name, {type:'application/octet-stream'})
        var dataURL = window.URL.createObjectURL(file);
        attachments.push(<a href={dataURL} download={json.name} key={json.name}>{json.name}</a>)

      })
    }

    const { message, subject, from, cc, date } = this.state
    if (message === null) {
      return <div className="mail-msg-modal" id="mail-modal" key={message}>
          <div className="mail-msg-modal-content">  
            <div className="no-messages">Retrieving message...</div>
          </div>
        </div>
    }
    let ccDiv = <div></div>
    if (cc !== null) {
      ccDiv = <div>Cc: {cc}</div>
    }
    return(
      <div className="mail-msg-modal" id="mail-modal" key={message}>
        <div className="mail-msg-modal-content">  
          <div className="mail-msg-modal-subject">
            <table width="100%">
              <tbody>
                <tr>
                  <td align="left" >{subject}</td>
                  <td width="3px">
                    <div className="mail-msg-modal-close" onClick={this.handleMailCloseClick} >X</div>
                  </td>
                </tr>
              </tbody>
            </table> 
          </div>
          <div key={this.state.showSource}>{header}</div>
          <hr></hr>
          <div>Date: {date}</div>
          <div>From: {from}</div>
          {ccDiv}
          <div>{attachments}</div>
          {this.state.message}
        </div>
        <ResetTimeout />
      </div>
    );
  }
}
