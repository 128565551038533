import PropTypes from 'prop-types';
import React from 'react';
import './MailCompose.css';
import { EMAIL_ENDPOINT } from './api-backend';
import Roller from '../roller/Roller'

async function sendMail(credentials) {
  console.log("MailCompose sendMail")
  const sendMailResp = fetch(EMAIL_ENDPOINT, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())
      .catch(error =>(
        console.log(error)
      ))
  console.log(sendMailResp)
  return sendMailResp
}

const FileUploader = ({onFileSelectSuccess, onFileSelectError}) => {

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    console.log(file)
    if (file.size > 1024*1024*10)
      onFileSelectError({ error: "File size cannot exceed more than 10MB" });
    else onFileSelectSuccess(file);
  }

  return (
      <div className="file-uploader">
          <input type="file" id="compose-file" onChange={handleFileInput} />
      </div>
  )
}

const loadFile = src =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(src)
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  })  
;

export default class MailCompose extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: props.from,
      displayName: props.displayName, 
      key: null,
      body: null,
      to: null,
      cc: null,
      bcc: null,
      selectedFile: null,
      subject: null,
      loading: false,
    };
  }

  componentDidMount() {
    console.log("MailCompose.componentDidMount " + this.state.from)
    
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("MailCompose.componentDidUpdate " + this.state.from)
  }

  async handleSendClick(e){
    console.log("MailCompose handleSubmit")
    e.preventDefault();

    if (this.state.to === null && this.state.cc === null && this.state.bcc === null) {
      let error = document.getElementById('compose-error');
      error.style.display="block"
      error.innerHTML = "At least one recipient is required"
      return
    }
    this.setState({'loading': true})

    const selectedFile = this.state.selectedFile
    var data = null
    var filename = null

    if (selectedFile !== null) {
      console.log("MailCompose handleSendClick selectedFile " + selectedFile)
      filename = selectedFile.name
      await loadFile(selectedFile)
        .then(result =>{
        console.log(result)
        data = result
      })
      console.log("MailCompose handleSendClick data " + data)
    }

    var from = this.state.from
    if (this.state.displayName !== undefined && this.state.displayName !== null) {
      from = this.state.displayName + " <" + from + ">"
    }
    console.log("MailCompose handleSendClick from " + from)

    const payload = {
      "action": "send",
      "token": sessionStorage.getItem('token'),
      "from": from,
      "to": this.state.to,
      "cc": this.state.cc,
      "bcc": this.state.bcc,
      "subject": this.state.subject,
      "body": document.getElementById('compose-body').innerHTML,
      "file": data,
      "fileName": filename
    }
    console.log("MailCompose handleSendClick sending - " + JSON.stringify(payload))

    const sendResp = await sendMail(payload);
    let json_resp = sendResp
    if (json_resp.statusCode !== 401 && json_resp.sent === undefined) {
      json_resp = JSON.parse(sendResp)
    }
    console.log(json_resp)

    this.setState({'loading': false})
    if (json_resp.sent) {
      console.log("MailCompose handleSendClick successfully sent")
      this.setState({key: Date()})
      let modal = document.getElementById('compose-modal');
      modal.style.display = "none";
    } else {
      let send = document.getElementById('compose-send-button');
      send.style.display="block"
      let error = document.getElementById('compose-error');
      error.style.display="block"
      error.innerHTML = "Any error occurred and the message wasn't sent"

      throw new Error("Message not sent");
    }
  }

  handleComposeClick() {
    let modal = document.getElementById('compose-modal');
    modal.style.display = "block";
  }
  handleComposeCloseClick = async e => {
    console.log("MailCompose.handleComposeCloseClick")
    this.setState({key: Date()})
    let modal = document.getElementById('compose-modal');
    modal.style.display = "none";
  }
  handleToChange(e) {
    console.log("MailCompose: handleToChange")
    this.setState({to: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }
  handleCcChange(e) {
    this.setState({cc: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }
  handleBccChange(e) {
    this.setState({bcc: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }
  handleSubjectChange(e) {
    this.setState({subject: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }
  handleMessageChange(e) {
    this.setState({body: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }
  setSelectedFile(e) { 
    console.log("MailCompose.setSelectedFile: " + e)
    this.setState({selectedFile: e})
    let error = document.getElementById('compose-error');
    error.style.display="none"
  }

  render() {
    const {loading} = this.state
    const emails = sessionStorage.getItem("emails")
    let email_opts = []
    if (emails !== null) {
      const email_list = emails.split(",")
      // console.log("email_list " + email_list)
      email_list.forEach(email => {
        email_opts.push(<option key={email}>{email}</option>)
      })
    }
    // console.log("email_opts " + email_opts)
    return (
      <div>
        <div className="mail-folder" onClick={() => this.handleComposeClick()}>Compose</div>
        <div id="compose-modal" className="compose-modal" key={this.state.key}>
          <div className="compose-modal-content">
            <div className="compose-modal-header">
                <table width="100%"><tbody><tr>
                  <td align="left">New Message</td>
                  <td width="3px">
                    <div className="compose-modal-close" onClick={this.handleComposeCloseClick} >X</div>
                </td></tr></tbody></table>
            </div>
            <div style={{padding: "10px"}}>
              <span>
                <input
                    id="compose-to" 
                    type="email" 
                    list="emails"
                    onChange={e => this.handleToChange(e.target.value)}
                    placeholder="To"
                    multiple/>
                <datalist id="emails">{email_opts}</datalist>
              </span>
            </div>
            <div style={{padding: "10px"}}>
              <span>
                <input
                    id="compose-cc" 
                    type="email" 
                    list="emails"
                    onChange={e => this.handleCcChange(e.target.value)}
                    placeholder="Cc"
                    multiple/>
                <datalist id="emails">{email_opts}</datalist>
              </span>
            </div>
            <div style={{padding: "10px"}}>
              <span>
                <input
                    id="compose-bcc" 
                    type="email" 
                    list="emails"
                    onChange={e => this.handleBccChange(e.target.value)}
                    placeholder="Bcc"
                    multiple/>
                <datalist id="emails">{email_opts}</datalist>
              </span>
            </div>
            <div style={{padding: "10px"}}>
              <input id="compose-subject" type="text" onChange={e => this.handleSubjectChange(e.target.value)} placeholder="Subject"/>
            </div>
            <div style={{padding: "10px"}} key={this.state.selectedFile}>
              <FileUploader id="uploader"
                onFileSelectSuccess={(file) => this.setSelectedFile(file)}
                onFileSelectError={({ error }) => alert(error)}
                // ref={this.fileUploaderRef}
                />
            </div>
            <div id='compose-body' 
              style={{padding: "10px", width: "470px", height: "200px",border: "1px solid #ccc", "overflowY": "scroll"}}
              contentEditable="true"></div>
            <div style={{padding: "10px"}}>
              <div id="compose-error" className="compose-error"></div>
              <div id="compose-send-button" 
                   className="send-button"
                   onClick={e => this.handleSendClick(e)}>Send</div>
            </div>
          </div>
        </div>
        {loading ? <Roller /> : null}
      </div>
    )};
}

MailCompose.propTypes = {
  from: PropTypes.string.isRequired
};