import React from 'react';
import './Contacts.css';
import { CONTACTS_ENDPOINT } from '../components/api-backend';
import ContactModal from './ContactModal';
import EmailAddress from './EmailAddress';
import Roller from '../roller/Roller';
import UploadModal from './UploadModal';

export default class Contacts extends React.Component {
  // const {email, password, verification, setEmail} = useSignUp();
  constructor() {
    super();
    this.state = {
      in_progress: false,
      selected_items: [],
      loading: false,
      uploadDisplay: "none"
    }
    this.handleDeleteClick = this.handleDeleteClick.bind(this)
    this.handleEdit = this.handleEdit.bind(this)
    this.load = this.load.bind(this)
    
    this.contactModalRef = React.createRef()
    this.uploadModalRef = React.createRef()
    // sessionStorage.setItem('contacts', null)
  }

  componentDidMount() {
    console.log("Contacts.componentDidMount")
    const contacts = sessionStorage.getItem('contacts')
    // console.log("contacts" + contacts)
    if (contacts === null) { 
      this.load()
    }
    // sessionStorage.setItem('contacts', null)
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Contacts.componentDidUpdate")
  }

  async reload(){
    let response = this.load()
    await response
  }

  async delete(item) {
    console.log("Contacts.delete: "  + item)
    this.setState({in_progress: true})
    const token = sessionStorage.getItem('token');
    const request = {
      "action": "delete",
      "token" : token,
      "contactId": item
    }
    
    const resp = await fetch(CONTACTS_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      })
      .then(response => response.json())
      .then(json => {
        console.log("Contacts.delete: statusCode " + json.statusCode)
        console.log("Contacts.delete: json: " + json);
      })
      .catch( err => {
        console.log("Contacts.delete: Error: " + err)
      });
    await resp
  }

  async load() {
    console.log("Contacts.load")
    // sessionStorage.setItem('contacts', null)
    this.setState({loading:true})
    const token = sessionStorage.getItem('token');
    if (token === undefined || token === null) {
      window.location = "./"
      return 
    }
    const request = {
        "action": "get",
        "token" : token
    }
    await fetch(CONTACTS_ENDPOINT, {
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(request)
      }).then(response => {
          if (response.status === 401) {
            sessionStorage.clear()
            window.location = "./"
          }
          return response.json()
        })
        .then(json => {
          // console.log("Contacts.load: " + json.statusCode)
          
          sessionStorage.setItem("contacts", JSON.stringify(json))
          // this.setState({contacts: json})
          console.log("Contacts.load json", json)
          // this.setState({loading:true})
          this.setState({loading:false})
          return json
          })
        .catch( err => {
          console.log("Contacts.load: Error " + err)
          this.setState({loading:false})
        });
  }

  hanldeCheckboxChange = async e =>{
    console.log("Contacts.hanldeCheckboxChange: value " + e.target.value)
    console.log("Contacts.hanldeCheckboxChange: selected " + e.target.checked)
    var selected = this.state.selected_items
    if (e.target.checked) {
      selected.push(e.target.value)
    } else {
      var index = selected.indexOf(e.target.value)
      console.log("Contacts.hanldeCheckboxChange: value index " + index)
      if (index !== -1) {
        console.log("Contacts.hanldeCheckboxChange: splicing " + index)
        selected.splice(index, 1);
      }
    }
    this.setState({selected_items:selected})
    console.log("Contacts.hanldeCheckboxChange: selected_items " + this.state.selected_items)
  }

  async handleDeleteClick() {
    console.log("Contacts.handleDeleteClick " + this.state.selected_items)
    this.setState({loading:true})

    var response = null
    this.state.selected_items.forEach(item => {
       response = this.delete(item)
    })
    
    await response

    this.load()
    this.setState({loading:false})
    this.setState({selected_items:[]})
  }

  handleCreateClick = async e => {
    console.log("Contacts.handleCreateClick ")
    this.contactModalRef.current.showModal()
    this.contactModalRef.current.setEmailAddresses([])
  }

  handleEdit = contact => event => {
    console.log("Contacts.handleEdit " + JSON.stringify(contact))
    this.contactModalRef.current.setContact(contact)
    this.contactModalRef.current.showModal()
  }

  handleUploadClick = async e =>  {
    console.log("Contacts.handleUploadClick ")
    this.setState({uploadDisplay:"flex"})
    this.uploadModalRef.current.showModal()
  }

  render() {
    console.log("Contacts.render")
    const { loading } = this.state;
    const contacts = JSON.parse(sessionStorage.getItem("contacts"))
    const list = []
    
    if (loading && (contacts === null || contacts === "null" || contacts === undefined)) {
      return (
        <Roller key={loading} display={loading}/>
      )
    }
    // console.log("contacts " + contacts)
    try {
      contacts.forEach(contact => {
        const addresses = []
        const emails = []
        const numbers = []
        // console.log("contact " + contact)
        if (contact.addresses !== undefined && contact.addresses !== null) {
          contact.addresses.forEach(addr => {
            var type = ""
            if (addr.type !==null) {
              type = " (" + addr.type + ")"
            }
            addresses.push(<div key={addr.address}>{addr.address}{type}</div>)
          });
        }
        if (contact.email_addresses !== undefined && contact.email_addresses !== null) {
          contact.email_addresses.forEach(email => {
            emails.push(
              <EmailAddress 
                key={email.email_address} 
                email_address={email.email_address} 
                type={email.type} 
              />
            )
          });
        }
        if (contact.phone_numbers !== undefined && contact.phone_numbers !== null) {
          contact.phone_numbers.forEach(phone => {
            var type = ""
            if (phone.type !==null) {
              type = " (" + phone.type + ")"
            }
            numbers.push(<div key={phone.number}>{phone.number}{type}</div>)
          });
        }
        list.push(
          <tr  key={contact.contactId}>
            <td>
              <input type="checkbox" onChange={e => this.hanldeCheckboxChange(e)} value={contact.contactId}/>
            </td>
            <td onClick={this.handleEdit(contact)}>{contact.name}</td>
            <td onClick={this.handleEdit(contact)}>{emails}</td>
            <td onClick={this.handleEdit(contact)}>{numbers}</td>
            <td onClick={this.handleEdit(contact)}>{contact.birthday}</td>
            <td onClick={this.handleEdit(contact)}>{addresses}</td>
          </tr>
        )
      })

    } catch(error) {
      console.log("contacts error " + error) 
    }

    if (list.length === 0) {
      list.push(
        <tr>
          <td colspan="6">
            <div className="no-messages">No contacts to display</div>
          </td>
        </tr>
      )
    }

    return (
      <div>
        <div className='contacts-menu'>
          <button
              className="login-btn" 
              onClick={this.handleCreateClick}>Create</button>
          &nbsp;
          <button
              className="login-btn" 
              onClick={this.handleDeleteClick}>Delete</button>
          &nbsp;
          <button
              className="login-btn" 
              onClick={this.handleUploadClick}>Upload</button>
          {/* <span style={{padding: "5px"}}>
            <input
                key="moveTo"
                className="moveTo"
                id="moveTo"
                list="folders"
                style={{textTransform: 'capitalize'}}
                onKeyUp={e => this.onKeyDown(e)}
                // on_Change={e => this.handleMoveChange(e)}
                placeholder=""/>
            <datalist id="folders" className="moveTo" >{options}</datalist>
          </span> */}
          <div className='contacts'>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>B-Day</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody>
                {list}
              </tbody>
            </table>
          </div>
        </div>
        {loading ? <Roller /> : null}
        <UploadModal ref={this.uploadModalRef}/>
        <ContactModal ref={this.contactModalRef} load={this.load}/>
      </div>
    )
  };
}
