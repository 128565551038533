import React from 'react';
import * as ReactDOM from 'react-dom/client';
import LoginButton from './login-button';
import LogoutButton from './logout-button';
import TimeoutModal from './session-timeout-modal';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { decrement } from '../app/timeoutSlice'

// const timeoutInterval = 500;

class LoginControl extends React.Component {

  constructor(props) {
    super(props);
    this.handleAuthClick = this.handleAuthClick.bind(this);
    this.handleLoginClick = this.handleLoginClick.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.handleLogoutClick = this.handleLogoutClick.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
      
    this.state = {
        isAuthenticated: false,
        isLoggedIn: false,
        isModalRoot:false
        // timeout: timeoutInterval
    };

    // this.navigation = props.navigation
  }

  componentDidMount() {
    console.log("LoginControl.componentDidMount")
    const isAuthenticated = sessionStorage.getItem('auth');
    const isLoggedIn = sessionStorage.getItem('token');
    if (isLoggedIn && isAuthenticated) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
    console.log("LoginControl.componentWillUnmount")
  }
    
  startTimer() {
    const { timeout } = this.props;
    
    // const timeout = selector((state) => state.timeout.value)
    
    this.setState({timeout: timeout});
    this.timerID = setInterval(
      () => this.tick(),
      1000
    );
  }
    
  handleModalClose() {
      const modalRoot = document.getElementById('modal-root');
      modalRoot.style.display = "none";
      window.location = "./";
  }

  tick() {
    const { timeout, dispatch } = this.props;
    dispatch(decrement())
    // const timeout = selector((state) => state.timeout.value)
    // const dispatch = useDispatch()
    // let timeout = Number.parseInt(this.state.timeout)
    // timeout = timeout - 1;
    //console.log("tick " + timeout)
    this.setState({
      timeout: timeout
    });
      
    if (timeout <= 0) {
      clearInterval(this.timerID);
      this.handleLogout()
        
      console.log("LoginControl: raise timeout modal")
      let modalRoot = document.getElementById('modal-root');
      modalRoot.style.display = "block";
      if (!this.state.isModalRoot ) {
          modalRoot = ReactDOM.createRoot(modalRoot);
          modalRoot.render(<TimeoutModal onClick={this.handleModalClose}/>);
          this.setState({isModalRoot:true});
      }           
    }
  }
    
  handleAuthClick() {
    sessionStorage.setItem('auth', 'true');
    this.setState({isAuthenticated: true});
    this.startTimer();
  }

  handleLoginClick() {
    const { navigation } = this.props;
    navigation("./login")
  }

  handleLogout() {
    clearInterval(this.timerID);
    console.log("LoginControl: logging out")
    sessionStorage.clear();
    console.log("LoginControl: logged out")
  }

  handleLogoutClick() {
    this.handleLogout()
    const { navigation } = this.props;
    navigation("./");
  }

  render() {

    const isAuthenticated = sessionStorage.getItem('auth');
    const isLoggedIn = sessionStorage.getItem('token');
    let button;
    if (isLoggedIn && isAuthenticated) {
      button = <div>
          <LogoutButton onClick={this.handleLogoutClick} />
          <br/>{this.state.timeout}s timeout
          </div>;
    } else if (isLoggedIn) {
      button = <div></div>;
    } else {
      button = <LoginButton onClick={this.handleLoginClick} />;
    }

    return (
      <div key={isAuthenticated + isLoggedIn}>
        {button}
      </div>
    );
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (props) {
  const sele = useSelector((state) => state.timeout.value)
  const dis = useDispatch()
  const nav = useNavigate()
  return <LoginControl {...props} navigation={nav} timeout={sele} dispatch={dis} />
}
