import React from 'react';
import './EmailAddress.css'
export default class Address extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: props.editable,
            address: props.address,
            id: props.id,
            type: props.type,
        }
    }

    componentDidMount() {
        const { editable } = this.state
        console.log("Address.componentDidMount")
        if (editable === undefined) {
            this.setState({ editable: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("Address.componentDidUpdate")        
    }

    change(id, addr, type) {
        console.log("change ", id)
        // this.props.callback(id, email, type)
        this.setState({address: addr})
        this.setState({type: type})
    }

    get(){
        const { address, type } = this.state
        return {
            "address":address,
            "type":type
        }
    }

    render() {
        const { editable, address, id, type } = this.state
        // console.log("Address.render address " + address)
        if (editable){
            return <span className='email-address'>
                <input autoComplete='new-password' 
                    id="email"
                    type="email"
                    defaultValue={address}
                    onChange={e => this.change(id, e.target.value, type)} />
                &nbsp;
                <input autoComplete='new-password' 
                    id="type"
                    type="email"
                    defaultValue={type}
                    size="2"
                    list="types"
                    onChange={e => this.change(id, address, e.target.value)} />
                <datalist id="types">
                    <option value="Home">Home</option>
                    <option value="Other">Other</option>
                    <option value="Work">Work</option>
                </datalist>
                </span>
        }
        return <div>
            <span>{address}&nbsp;({type})</span>
        </div>
    }
}  