import React from 'react';
import './EmailAddress.css'
export default class EmailAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            editable: props.editable,
            email_address: props.email_address,
            id: props.id,
            type: props.type,
        }
        // this.callback = props.callback
    }

    componentDidMount() {
        const { editable } = this.state
        // console.log("EmailAddress.componentDidMount")
        if (editable === undefined) {
            this.setState({ editable: false })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        // console.log("EmailAddress.componentDidUpdate")        
    }

    change(id, email, type) {
        console.log("change ", id)
        // this.props.callback(id, email, type)
        this.setState({email_address: email})
        this.setState({type: type})
    }

    delete(id) {
        console.log("delete ", id)
        // this.props.callback(id, null, null, true)
    }

    get(){
        const { email_address, type } = this.state
        return {
            "email_address":email_address,
            "type":type
        }
    }

    render() {
        const { editable, email_address, id, type } = this.state
        console.log("email_address " + email_address)
        if (editable){
            return <span className='email-address'>
                <input autoComplete='new-password' id="email" type="email" defaultValue={email_address} onChange={e => this.change(id, e.target.value, type)} />
                &nbsp;
                <input autoComplete='new-password' id="type" type="email" defaultValue={type} size="2" list="types" onChange={e => this.change(id, email_address, e.target.value)} />
                <datalist id="types">
                    <option value="Home">Home</option>
                    <option value="Other">Other</option>
                    <option value="Work">Work</option>
                </datalist>
                </span>
        }
        return <div>
            <span>{email_address}&nbsp;({type})</span>
        </div>
    }
}  