import React from 'react';
import './Roller.css'

export default class Roller extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      display: "none",
    }
    this.setEmail = props.setEmail
  }

  toggleDisplay(){
    const { display } = this.state
    console.log("Roller.toggleDisplay " + display)
    if (display !== undefined){
      let loading = document.getElementById('loading-roller');
      loading.style.display = display;
    }  
  }

  componentDidMount() {
    console.log("Roller.componentDidMount")
    // this.toggleDisplay()
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("Roller.componentDidUpdate")
    // this.toggleDisplay()
  }

  showLoading() {
    console.log("Roller.showLoading")
    this.setState({display: 'flex'})
    this.toggleDisplay()
  }
  hideLoading() {
    console.log("Roller.hideLoading")
    this.setState({display: 'none'})
    this.toggleDisplay()
  }

  render() {
    const {display} = this.state
    return (
      <div key={display} className="lds-roller" id="loading-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    )
  }
}