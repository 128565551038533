import React from 'react';
import './MoviePlayer.css';
import { DEVICE_ID_HEADEER, MEDIA_URL_ENDPOINT } from '../components/api-backend';
import MediaButton from './MediaButton';
import Movie from './Movie';
import MoviePlayer from './MoviePlayer';

export default class MovieDetail extends React.Component {

  constructor(props) {
    super(props);
    console.log(props)
    this.state = {
      name: props.name === undefined ? "": props.name,
      source: props.source,
      poster_url: props.poster_url,
      // loading: true
    }
    
    this.playerRef = React.createRef()
    this.refresh = this.refresh.bind(this)
  }

  componentDidMount() {
    console.log("MovieDetail.componentDidMount")
  };

  componentWillUnmount() {
    // this.pauseVideo();
  };

  playVideo() {
    const {source} = this.state
    const deviceId = localStorage.getItem(DEVICE_ID_HEADEER)
    var headers = {
      'Content-Type': 'application/json'
    }
    if (deviceId !== null) {
      headers = {
        'Content-Type': 'application/json',
        'X-Elliott-Technologies-Device-Id': deviceId
      }
    }
    console.log("MovieDetail.playVideo")
    fetch(MEDIA_URL_ENDPOINT + '?key=' + source, {
        headers: headers
      }).then(response => {
          if (response.status === 401) {
            sessionStorage.clear()
            window.location = "./media-auth"
          }
          return response.json()
        })
        .then(json => {
          this.setState({movies: json.videos})
          console.log("Movies.load json", json)
          this.setState({loading:false})
          this.playerRef.current.setSource(json.url)
          this.playerRef.current.setName(source)
          this.playerRef.current.playVideo()
          })
        .catch( err => {
          console.log("Movies.load: Error " + err)
          this.setState({loading:false})
        }); 
  };

  
  search() {
    const key = "6ab296d2a9d4ca7806369f0673b69746"
    const { name, source } = this.state
    fetch('https://api.themoviedb.org/3/search/movie?query=' + name + '&api_key=' + key, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
          return response.json()
        })
        .then(json => {
          console.log("json", json)
          let resultList = []
          json.results.forEach(result => {
            console.log("title: " + result.title)
            if (result.poster_path == null) {
              return
            }
            const movie = <Movie
                key={result.id}
                poster_url={result.poster_path}
                name={result.title}
                overview={result.overview}
                source={source}
                release_date={result.release_date}
                id={result.id}
              />
            resultList.push(movie)
          })
          this.setState({results:resultList})
        })
        .catch( err => {
          console.log("Movies.load: Error " + err)
          this.setState({loading:false})
        }); 
  }
  
  setTmdbId(id){
    this.setState({tmdb_id:id})
    if (id === undefined) {
      return
    }
    const key = "6ab296d2a9d4ca7806369f0673b69746"
    fetch('https://api.themoviedb.org/3/movie/' + id + '?api_key=' + key, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
          return response.json()
        })
        .then(json => {
          console.log("backdrop_path", json.backdrop_path)
          const div = document.getElementById("movieDetailBgRef")
          div.style.backgroundImage = 'url(https://image.tmdb.org/t/p/w780' + json.backdrop_path + ')'
          div.style.backgroundPosition = "center";
          div.style.backgroundRepeat = "no-repeat";
          div.style.backgroundSize = "cover";
          div.style.display = "block"
          var genres = []
          json.genres.forEach(genre=>{
            genres.push(genre.name)
          })
          this.setState({
            genres: genres,
            overview:json.overview,
            popularity: json.popularity,
            poster_url: json.poster_path,
            release_date: json.release_date,
            runtime: json.runtime,
            vote_average: json.vote_average
          })
          
          })
        .catch( err => {
          console.log("Movies.load: Error " + err)
          this.setState({loading:false})
        }); 
    
  }
  
  setName(n){
    const name = n.replace('.m4v','')
    this.setState({name:name})
  }
  setPosterUrl(u){
    this.setState({poster_url:u})
  }
  setSource(src){
    this.setState({source:src})
  }
  
  startOver(){
    const {source} = this.state
    localStorage.setItem(source + "currentTime", 0)
    this.playVideo()
  }
  
  refresh(){
    const {source} = this.state
    this.setName(source)
  }
  
  closeDetail(){
    document.getElementById("movieDetailBgRef").style.display = "none";
    document.getElementById("movieDetailRef").style.display = "none";
    document.getElementById("movieDetailRef").style.backgroundImage = "none";
    this.setState({results:""})
  }
  

  render() {
    const { genres, name, overview, popularity, poster_url, release_date, results, runtime, source, vote_average } = this.state;
    const time = localStorage.getItem(source + "currentTime")
    
    var buttons = <div>
        <MediaButton altSource="X" customClickEvent={this.closeDetail.bind(this)}>Close</MediaButton>
    	<MediaButton altSource=">" customClickEvent={this.playVideo.bind(this)}>Play</MediaButton>
      </div>
    if (time > 0) {
      buttons = <div>
        <MediaButton altSource="X" customClickEvent={this.closeDetail.bind(this)}>Close</MediaButton>
    	<MediaButton altSource=">" customClickEvent={this.playVideo.bind(this)}>Resume</MediaButton>
    	<MediaButton altSource="|>" customClickEvent={this.startOver.bind(this)}>Start Over</MediaButton>
      </div>
    }
    
    var elem = null
    if (poster_url !== undefined) {
    	console.log("MovieDetail.render found poster_url")
    	elem = <div>
    	<table>
        <tbody>
          <tr>
          <td>
            <img
            src={'https://image.tmdb.org/t/p/w342' + poster_url} 
            alt="movie poster"
            />
          </td>
            <td>
              {overview}
                <p>Genres: {genres}</p>
                <p>Release date: {release_date}</p>
                <p>Runtime: {runtime}min</p>
                <p>Popularity: {popularity}</p>
                <p>Vote Average: {vote_average}</p>
              </td>
          </tr>
        </tbody>
    	</table>
    	  
    	
    	</div>
    }
    else {
      //const title = name.replace('.m4v','')
      elem = 
        <div>
          {name}
          <div className='video-player-close' onClick={() => this.search()}>?</div>
          {results}
        </div>
    }
    return  <div id="movieDetailRef" className="movie-detail">
        {buttons}
    	{elem}
        <MoviePlayer ref={this.playerRef} refresh={this.refresh}/>
      </div>
  };
}
