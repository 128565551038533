import React from 'react';
import './MoviePlayer.css';

export default class Movie extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      source: props.source,
      altSource: props.altSource
    }
  }

  componentDidMount() {
  };

  componentWillUnmount() {
  };

  render() {
    const { altSource, source } = this.state;
    var img = <img
    	  src={source} 
    	  alt={altSource}
    	/>
    return  <div className='media-button-wrapper'>
        <div className='media-button'  onClick={this.props.customClickEvent}>
        {img}<br />
        </div>
        {this.props.children}
      </div>
  };
}
